import React from "react";
import { ColorType, useTheme } from "./AHTheme/AHTheme";
import { View } from "react-native";
import { AntDesign, Feather, MaterialIcons } from "@expo/vector-icons";

export const AHListItemIcon = ({
  type,
  backgroundColor,
}: {
  type: AHIconType_legacy;
  backgroundColor?: ColorType;
}) => {
  const theme = useTheme();
  const bgColor = backgroundColor ? theme[backgroundColor] : undefined;
  switch (type) {
    case AHIconType_legacy.Instructions:
      return (
        <AHListItemIconContainer color={theme.primary}>
          <Feather name="clipboard" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.Team:
    case AHIconType_legacy.Users:
      return (
        <AHListItemIconContainer color={theme.getColor(3)}>
          <Feather name="users" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.Notifications:
      return (
        <AHListItemIconContainer color={bgColor ?? theme.getColor(5)}>
          <Feather name="bell" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.Expire:
      return (
        <AHListItemIconContainer color={theme.negative}>
          <Feather name="clock" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.Pin:
      return (
        <AHListItemIconContainer color={theme.primary}>
          <AntDesign name="pushpin" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.Schedule:
      return (
        <AHListItemIconContainer color={theme.primary}>
          <Feather name="calendar" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.TaskList:
      return (
        <AHListItemIconContainer color={theme.getColor(5)}>
          <Feather name="clipboard" size={15} color={theme.lightText} />
        </AHListItemIconContainer>
      );
    case AHIconType_legacy.Routine:
      return (
        <AHListItemIconContainer color={theme.primary}>
          <MaterialIcons name="loop" color={theme.lightText} size={19} />
        </AHListItemIconContainer>
      );
  }
};

const AHListItemIconContainer: React.FC<{ color?: string }> = ({
  color,
  children,
}) => (
  <View
    style={{
      width: 30,
      height: 30,
      borderRadius: 5,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: color,
    }}
  >
    {children}
  </View>
);
export enum AHIconType_legacy {
  Users = 0,
  Notifications = 1,
  Expire = 2,
  Pin = 3,
  Schedule = 4,
  TaskList = 5,
  Team = 6,
  Routine = 7,
  Instructions = 8,
}

import { InMemoryCache } from "@apollo/client/cache";

import { relayStylePagination } from "@apollo/client/utilities";
import { occasionPagination } from "./occasionPagination";
import { todoPagination } from "./todoPagination";

const possibleTypes = {
  Widget: [
    "WidgetTodoOverview",
    "WidgetIssueOverview",
    "WidgetRoutineOverview",
    "WidgetTaskCollection",
    "WidgetTaskSingle",
    "WidgetRoutinePositivePercentage",
    "WidgetNote",
    "WidgetWeather",
    "WidgetIntegrationIpool",
    "WidgetIntegrationPushAppy",
    "WidgetTeamSchedule",
  ],
  WidgetOverview: [
    "WidgetTodoOverview",
    "WidgetIssueOverview",
    "WidgetRoutineOverview",
    "WidgetIntegrationIpool",
    "WidgetIntegrationPushAppy",
    "WidgetTeamSchedule",
  ],
  Task: ["TaskNumber", "TaskText", "TaskCheck"],
  TaskSection: [
    "TaskSectionTaskExecutionNumber",
    "TaskSectionTaskExecutionText",
    "TaskSectionTaskExecutionCheck",
  ],
  TaskExecutionConnection: [
    "TaskExecutionConnectionTaskExecutionNumber",
    "TaskExecutionConnectionTaskExecutionText",
    "TaskExecutionConnectionTaskExecutionCheck",
  ],
  TaskExecutionEdge: [
    "TaskExecutionEdgeTaskExecutionNumber",
    "TaskExecutionEdgeTaskExecutionText",
    "TaskExecutionEdgeTaskExecutionCheck",
  ],
  TaskExecution: [
    "TaskExecutionNumber",
    "TaskExecutionText",
    "TaskExecutionCheck",
  ],
  StorecastFocusable: [
    "StorecastTodoOverviewSubjectFocus",
    "StorecastIssueOverviewSubjectFocus",
    "StorecastRoutineOverviewSubjectFocus",
    "StorecastNoteSubjectFocus",
    "StorecastTaskSingleFocus",
    "StorecastTaskCollectionSubjectFocus",
    "StorecastRoutinePositivePercentageSubjectFocus",
    "StorecastWeatherSubjectFocus",
    "StorecastIntegrationIpoolFocus",
    "StorecastIntegrationPushAppyFocus",
    "StorecastTeamScheduleFocus",
  ],
  StorecastFocusWidgetFocus: [
    "StorecastTodoOverviewSubjectFocus",
    "StorecastIssueOverviewSubjectFocus",
    "StorecastRoutineOverviewSubjectFocus",
    "StorecastNoteSubjectFocus",
    "StorecastTaskSingleFocus",
    "StorecastTaskCollectionSubjectFocus",
    "StorecastRoutinePositivePercentageSubjectFocus",
    "StorecastWeatherSubjectFocus",
    "StorecastIntegrationIpoolFocus",
    "StorecastIntegrationPushAppyFocus",
    "StorecastTeamScheduleFocus",
  ],
};

export const createCache = () =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      StorecastFocus: {
        fields: {
          subject: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      Routine: {
        fields: {
          occasions: occasionPagination(),
        },
      },
      TodoCategoryTeam: {
        fields: {
          todos: todoPagination(),
        },
      },
      TodoCategory: {
        fields: {
          todos: todoPagination(),
        },
      },

      User: {
        fields: {
          teams: {
            merge(_existing = [], incoming) {
              return incoming;
            },
          },
          flags: {
            merge(existing = [], incomming = []) {
              return [...existing, ...incomming];
            },
          },
          stores: {
            merge(_existing = [], incoming) {
              return incoming;
            },
          },
        },
      },
      TaskSection: {
        fields: {
          taskExecutions: relayStylePagination(),
        },
      },
      Dashboard: {
        fields: {
          widgets: { merge: (_existing, incomming) => incomming },
          focus: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      WidgetNote: {
        fields: {
          pages: { merge: (_existing, incomming) => incomming },
          currentPageId: {
            read(existing) {
              return existing ?? null;
            },
          },
        },
      },
      WidgetTodoOverview: {
        fields: {
          allTodos: todoPagination(),
          todos: { merge: (_existing, incomming) => incomming },
        },
      },
      Todo: {
        fields: {
          tags: {
            merge: (_ex, incomming) => incomming,
          },
          images: {
            merge: (_existing, incomming) => {
              return incomming;
            },
          },
          assignees: {
            merge: (_existing, incomming) => {
              return incomming;
            },
          },
        },
      },
      StorecastScreen: { fields: { sessions: relayStylePagination() } },
      Query: {
        fields: {
          storecast: {
            merge: true,
          },
          me: {
            merge: true,
          },
          store: {
            merge: true,
          },
          todos: {
            merge: (_existing, incomming) => {
              return incomming;
            },
          },
          notes: {
            merge: (_existing, incomming) => {
              return incomming;
            },
          },
          widgetNote: {
            read(_, { args, toReference, isReference }) {
              return toReference({
                __typename: "WidgetNote",
                id: args ? args["id"] : "",
              });
            },
          },
          Routines: {
            merge: true,
          },
        },
      },

      Store: {
        fields: {
          files: relayStylePagination(),
        },
      },

      Mutation: {
        fields: {
          issue: {
            merge: true,
          },
          storecast: {
            merge: true,
          },
          me: {
            merge: (existing, incomming, { mergeObjects }) => {
              return mergeObjects(existing, incomming);
            },
          },
          dashboard: {
            merge: (existing, incoming, { mergeObjects }) => {
              return mergeObjects(existing, incoming);
            },
          },
          store: {
            merge: (existing, incomming, { mergeObjects }) => {
              return mergeObjects(existing, incomming);
            },
          },
          todo: {
            merge: (existing, incomming, { mergeObjects }) => {
              return mergeObjects(existing, incomming);
            },
          },
          Routines: {
            merge: (existing, incomming, { mergeObjects }) => {
              return mergeObjects(existing, incomming);
            },
          },
        },
      },
    },
  });

import { Platform, PlatformColor } from "react-native";

export type ColorType =
  | "primary"
  | "secondary"
  | "surface"
  | "surfaceBorder"
  | "background"
  | "negative"
  | "semiNegative"
  | "positive"
  | "neutral"
  | "washedText"
  | "lightText"
  | "darkText"
  | "darkerText"
  | "transparent"
  | "usersIcon";

type Shadow =
  | {
      shadowColor: string;
      shadowOffset: { width: number; height: number };
      shadowOpacity: number;
      shadowRadius: number;
    }
  | { elevation: number };

export type AHThemeType = Record<ColorType, string> & {
  getColor: (number: number) => string;
  colorScheme: string[];
  shadow: {
    weak: Shadow;
    medium: Shadow;
    strong: Shadow;
  };
};

export const colorScheme = [
  "#43DD87",
  "#F06072",
  "#6290EC",
  "#F5A623",
  "#A97AEC",
  "#57D9C4",
  "#FF92E8",
  "#CA1616",
  "#1115BA",
  "#B77878",
  "#6D826C",
  "#EC620A",
  "#80AEC1",
  "#764E2B",
  "#4F4F4F",
  "#F2A1A1",
];

const lightTheme: AHThemeType = {
  primary: "rgb(0, 102, 255)",
  secondary: "rgb(255, 220, 105)",
  negative: "rgb(255, 67, 90)",
  semiNegative: "rgb(255, 220, 105)",
  positive: "rgb(81, 224, 102)",
  neutral: "#8B8B8B",
  lightText: "#FFF",
  darkerText: "#000",
  darkText: "#5F5F5F",
  washedText: "rgb(151,151,155)",
  surface: "#FFFFFF",
  surfaceBorder: "#dfdfdf",
  background: "#F2F2F7",
  transparent: "transparent",
  usersIcon: "#F5A623",
  shadow: {
    weak:
      Platform.OS === "android"
        ? { elevation: 3 }
        : {
            shadowColor: "#CCC",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 1,
            shadowRadius: 3,
          },
    medium:
      Platform.OS === "android"
        ? { elevation: 5 }
        : {
            shadowColor: "#CCC",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.5,
            shadowRadius: 5,
          },
    strong:
      Platform.OS === "android"
        ? { elevation: 7 }
        : {
            shadowColor: "#CCC",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.5,
            shadowRadius: 15,
          },
  },
  colorScheme,
  getColor: (index: number | undefined) =>
    index === undefined
      ? colorScheme[Math.round(Math.random() * (colorScheme.length - 1))]
      : colorScheme[index % colorScheme.length],
};

const darkTheme: AHThemeType = {
  primary: lightTheme.primary,
  secondary: lightTheme.secondary,
  negative: lightTheme.negative,
  semiNegative: "rgb(255,225,100)",
  positive: "rgb(81,224,102)",
  neutral: "#666",
  lightText: "#000",
  background: "#333",
  surfaceBorder: "#333",
  darkerText: "#FFF",
  darkText: "#FFF",
  washedText: "#999",
  surface: "#555",
  transparent: "transparent",
  usersIcon: "#F5A623",
  shadow: {
    weak:
      Platform.OS === "android"
        ? { elevation: 3 }
        : {
            shadowColor: "#CCC",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 1,
            shadowRadius: 3,
          },
    medium:
      Platform.OS === "android"
        ? { elevation: 5 }
        : {
            shadowColor: "#CCC",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.5,
            shadowRadius: 8,
          },
    strong:
      Platform.OS === "android"
        ? { elevation: 7 }
        : {
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.45,
            shadowRadius: 25,
          },
  },
  colorScheme,
  getColor: (index: number | undefined) =>
    index === undefined
      ? colorScheme[Math.round(Math.random() * (colorScheme.length - 1))]
      : colorScheme[index % colorScheme.length],
};

export const useTheme = () => {
  return lightTheme;
};

import { AHThemeType, ColorType, useTheme } from "../AHTheme/AHTheme";
import { Layout, TextSize, useLayoutContext } from "../LayoutProvider";
import { PixelRatio, Platform, Text, TextProps, TextStyle } from "react-native";
import React, { useMemo } from "react";

import { BoxProps } from "../AHTheme/BoxProps";
import { useBoxStyle } from "../AHTheme/useBoxStyle";
import { useScaleFactor } from "../AHScaleFactor";

export enum AHTextFonts {
  REGULAR = "system-regular",
  BOLD = "system-bold",
  LIGHT = "system-light",
  MEDIUM = "system-medium",
  MONO = "system-mono",
  BOLDER = "system-bolder",
  ITALIC = "system-serif",
}

type TextSizes = "detail" | "body" | "title" | "large-title" | "xlarge-title";

export function getTextSize(size: TextSizes) {
  switch (size) {
    case "detail":
      return 12;
    case "body":
      return 16;
    case "title":
      return 19;
    case "large-title":
      return 28;
    case "xlarge-title":
      return 50;
  }
}

export const resolveAHTextStyle = (
  type: TextTypes | undefined,
  scaleFactor: number,
  theme: AHThemeType,
  layout: Layout
): Partial<TextStyle> => {
  switch (type) {
    case "body-big":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.body * scaleFactor
        ),
        fontFamily: AHTextFonts.REGULAR,
        color: theme.darkerText,
      };
    case "body":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.body * scaleFactor
        ),
        fontFamily: AHTextFonts.REGULAR,
        color: theme.darkText,
      };
    case "subtitle":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.body * scaleFactor
        ),
        fontFamily: AHTextFonts.BOLD,
        color: theme.darkerText,
      };

    case "stat-number":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.xlargeTitle * scaleFactor
        ),
        fontFamily: AHTextFonts.BOLDER,
        lineHeight: Platform.OS === "android" ? 35 : undefined,
        color: theme.darkerText,
      };
    case "title":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.title * scaleFactor
        ),
        fontFamily: AHTextFonts.BOLD,
        color: theme.darkerText,
      };
    case "title-big":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.largeTitle * scaleFactor
        ),
        fontFamily: AHTextFonts.BOLDER,
        color: theme.darkerText,
      };
    case "title-small":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.smallTitle * scaleFactor
        ),
        fontFamily: AHTextFonts.BOLD,
        color: theme.darkerText,
      };

    case "detail":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.detail * scaleFactor
        ),
        fontFamily: AHTextFonts.MEDIUM,
        color: theme.washedText,
      };
    case "info-small":
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.detail * scaleFactor
        ),
        fontFamily: AHTextFonts.BOLD,
        color: theme.darkerText,
      };
    default:
      return {
        fontSize: PixelRatio.roundToNearestPixel(
          layout.text.body * scaleFactor
        ),
        color: theme.darkText,
        fontFamily: AHTextFonts.REGULAR,
      };
  }
};

export type TextTypes =
  | "stat-number"
  | "title"
  | "title-big"
  | "title-small"
  | "body-big"
  | "body"
  | "subtitle"
  | "detail"
  | "info-small";

export type AHTextProps = {
  type?: TextTypes;
  font?: AHTextFonts;
  size?: number | TextSize;
  washed?: boolean;
  color?: ColorType | string;
  children: React.ReactNode;
  textAlign?: TextStyle["textAlign"];
} & TextProps &
  Omit<BoxProps, "color">;

const AHTextCmp = ({
  style,
  type,
  washed,
  size,
  font,
  color,
  textAlign,
  ...props
}: AHTextProps) => {
  const { theme, sf, layout, defaultStyle } = useAHTextTypeStyles(type);

  const boxStyle = useBoxStyle(props);
  const calcStyle = useMemo(() => {
    const s: any = {};

    if (textAlign) {
      s.textAlign = textAlign;
    }
    if (font) {
      s.fontFamily = font;
    }
    if (washed) {
      s.color = theme.washedText;
    }
    if (size) {
      if (typeof size === "number") {
        s.fontSize = PixelRatio.roundToNearestPixel(size * sf);
      } else {
        s.fontSize = PixelRatio.roundToNearestPixel(layout.text[size] * sf);
      }
    }
    if (color) {
      s.color = (theme as any)[color] ?? color;
    }

    return s;
  }, [color, font, layout.text, sf, size, textAlign, theme, washed]);

  const styles = useMemo(
    () => [boxStyle, defaultStyle, calcStyle, style],
    [boxStyle, defaultStyle, calcStyle, style]
  );
  return <Text {...props} style={styles} />;
};

export const AHText = React.memo(AHTextCmp);

export function useAHTextTypeStyles(type: TextTypes | undefined) {
  const theme = useTheme();
  const layout = useLayoutContext();
  const sf = useScaleFactor();
  const defaultStyle = useMemo(
    () => resolveAHTextStyle(type, sf, theme, layout),
    [layout, sf, theme, type]
  );
  return { theme, sf, layout, defaultStyle };
}

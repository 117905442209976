import {
  Circle,
  Defs,
  G,
  LinearGradient,
  Mask,
  Rect,
  Stop,
  Svg,
} from "react-native-svg";
import { PixelRatio, StyleSheet, View } from "react-native";
import React, { useRef } from "react";

import { Feather } from "@expo/vector-icons";
import { useTheme } from "../AHTheme/AHTheme";

const insetFraction = 2 / 3;

export const AHStatusCircle = React.memo(
  (props: {
    isFilled: boolean;
    filledColor: string;
    icon?: string;
    iconColor?: string;
    progress: number;
    trackIsFilled: boolean;
    trackFillColor: string;
    trackBackgroundColor: string;
    shouldInsetFill?: boolean;
    size: number;
  }) => {
    const theme = useTheme();

    const id = useRef(Math.random()).current;
    const pixelRatio = PixelRatio.get();

    const strokeWidth = Math.round(2 * pixelRatio);

    const size = Math.round(props.size * pixelRatio);

    const radius = Math.round(size / 2 - strokeWidth / 2 - strokeWidth);
    const middle = Math.round(size / 2);

    const perimeter = 2 * Math.PI * radius;

    return (
      <View
        style={{ width: props.size, height: props.size, position: "relative" }}
      >
        {props.icon ? (
          <InnerCircle
            icon={props.icon}
            size={props.size / 2.2}
            iconColor={props.iconColor ?? "#FFF"}
          />
        ) : null}

        <Svg
          width={props.size}
          height={props.size}
          style={{
            transform: [{ rotateZ: "-90deg" }, { rotateX: "180deg" }],
          }}
          viewBox={`0 0 ${size} ${size}`}
        >
          <Defs>
            <LinearGradient
              id={"grad" + id}
              x1="100%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <Stop offset="0" stopColor={theme.negative} stopOpacity={1} />
              <Stop offset="0.3" stopColor={theme.negative} stopOpacity={1} />
              <Stop offset="0.9" stopColor={theme.primary} stopOpacity={1} />
            </LinearGradient>
            <Mask
              id={"overlayCircle" + id}
              x="0"
              y="0"
              width={size}
              height={size}
            >
              <Circle
                cy={middle}
                cx={middle}
                r={radius}
                stroke="white"
                strokeDasharray={perimeter}
                strokeDashoffset={props.progress * perimeter}
                strokeWidth={strokeWidth * 1.05}
              />
            </Mask>
            <Mask
              id={"middleCircle" + id}
              x="0"
              y="0"
              width={size}
              height={size}
            >
              <Circle
                cy={middle}
                cx={middle}
                r={
                  radius -
                  (props.shouldInsetFill
                    ? (strokeWidth / 2 + strokeWidth) * insetFraction
                    : 0)
                }
                fill="white"
              />
            </Mask>
            <Mask
              id={"coloredCircle" + id}
              x="0"
              y="0"
              width={size}
              height={size}
            >
              <Circle
                cx={middle}
                cy={middle}
                stroke="white"
                r={radius}
                strokeWidth={strokeWidth}
              />
              {props.isFilled || props.trackIsFilled ? null : (
                <Circle
                  cy={middle}
                  cx={middle}
                  r={radius - strokeWidth}
                  stroke="black"
                  strokeWidth={strokeWidth}
                />
              )}
            </Mask>
          </Defs>

          <G mask={"url(#coloredCircle" + id + ")"}>
            <Rect
              x="0"
              y="0"
              width={size}
              height={size}
              fill={
                props.isFilled
                  ? props.filledColor
                  : props.trackIsFilled
                  ? props.trackFillColor
                  : theme.primary
              }
            />
            {!props.isFilled && !props.trackIsFilled ? (
              <Rect
                x={size - size * 0.8}
                y={size * 0.5}
                width={size * 0.8}
                height={size * 0.5}
                fill={"url(#grad" + id + ")"}
              />
            ) : null}
          </G>
          {props.isFilled ? (
            <G mask={"url(#middleCircle" + id + ")"}>
              <Rect
                x="0"
                y="0"
                width={size}
                height={size}
                fill={props.filledColor}
              />
            </G>
          ) : (
            <G mask={"url(#overlayCircle" + id + ")"}>
              <Rect
                x="0"
                y="0"
                width={size}
                height={size}
                fill={props.trackBackgroundColor}
              />
            </G>
          )}
        </Svg>
      </View>
    );
  }
);

export const InnerCircle = (props: {
  icon: string;
  iconColor: string;
  size: number;
}) => {
  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        {
          flex: 1,
          justifyContent: "center",
          zIndex: 99,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Feather
          name={props.icon as any}
          color={props.iconColor}
          size={props.size}
        />
      </View>
    </View>
  );
};

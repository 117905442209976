import React from "react";
import { AHButton, AHText, Box } from "arkh";
import { Image } from "react-native";
import { gql, useMutation } from "@apollo/client";
import { Modal } from "./Modal";

export function DeleteFileModal(props: {
  fileName?: string;
  fileId: string;
  onDismiss: () => void;
  onDeleted: () => void;
  thumbnail?: string;
}) {
  const [deleteFile, deleteInfo] = useMutation(
    gql`
      mutation DeleteFile($id: ID!) {
        store {
          file(id: $id) {
            delete
          }
        }
      }
    `,
    {
      variables: { id: props.fileId },
      refetchQueries: [
        "FilesScreenQuery",
        "FilesBrowserQuery",
        "FileQuoataQuery",
      ],
      onCompleted: props.onDeleted,
    }
  );

  return (
    <Modal onDismiss={props.onDismiss}>
      <Box style={{ justifyContent: "space-between", flex: 1 }} space="xlarge">
        <Box>
          <AHText type="title-big">Radera "{props.fileName}"</AHText>
          <AHText spaceTop="small" type="title-small">
            Vill du ta bort filen?
          </AHText>
        </Box>
        {props.thumbnail ? (
          <Box spaceVertical="large">
            <Box
              style={{ alignSelf: "center", overflow: "hidden" }}
              cornerRadius="medium"
              shadow="medium"
            >
              <Image
                source={{ uri: props.thumbnail, width: 256, height: 256 }}
              />
            </Box>
          </Box>
        ) : null}
        <Box style={{ flexDirection: "row" }}>
          <Box style={{ flex: 1 }} spaceRight="small">
            <AHButton
              style={{ flex: 1 }}
              themeType="negative"
              title="Ta bort"
              loading={deleteInfo.loading}
              onPress={() => {
                deleteFile();
              }}
            />
          </Box>
          <AHButton
            style={{ flex: 2 }}
            themeType="default"
            title="Avbryt"
            onPress={() => {
              props.onDismiss();
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
}

import React, { useEffect, useLayoutEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { AHButton, AHText, Box } from "arkh";
import { FileErrorReason, FileStatus } from "./globalTypes";
import { PendingFilesQuery } from "./__generated__/PendingFilesQuery";
import { Modal } from "./Modal";
import Lottie from "lottie-react";
import walk from "./walk.json";
function errorReasonDescription(reason: FileErrorReason | null): string {
  switch (reason) {
    case FileErrorReason.CONVERSION_ERROR:
      return "Kunde inte hantera filen";
    case FileErrorReason.TOO_LARGE:
      return "Filen för stor";
    case FileErrorReason.TYPE_NOT_SUPPORTED:
      return "Filtypen stöds inte";
    default:
      return "Okänt fel inträffade";
  }
}

export function ProgressIndicator(props: { percent: number }) {
  return (
    <div
      style={{
        overflow: "hidden",
        height: 20,
        width: 400,
        position: "relative",
        background: "rgb(231, 231, 231)",
        borderRadius: 2,
        flexDirection: "row",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          top: 0,
          left: 0,
          transition: "2s width",
          width: `${props.percent}%`,
          background: "#0066ff",
        }}
      />
    </div>
  );
}
export function UpploadModal(props: {
  pendingFileIds: string[];
  uploadProgress: number;
  onDismiss: () => void;
}) {
  useLayoutEffect(() => {
    const before = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = before;
    };
  }, []);
  const { data, stopPolling, startPolling } = useQuery<PendingFilesQuery>(
    gql`
      query PendingFilesQuery($fileIds: [ID!]!) {
        store {
          id
          listFiles(fileIds: $fileIds) {
            id
            name
            status
            errorReason
            url
            type
            createdAt
          }
        }
      }
    `,
    { variables: { fileIds: props.pendingFileIds } }
  );

  console.log(data?.store.listFiles.map((ls) => ls.status));

  const activePending = data?.store?.listFiles.filter(
    ({ status }) => status === FileStatus.PENDING
  ).length;

  const percentPendingFiles =
    activePending === undefined
      ? 0
      : activePending / props.pendingFileIds.length;

  const isDone =
    props.uploadProgress === 1 && data?.store.listFiles && activePending === 0;

  useEffect(() => {
    if (isDone) {
      startPolling(1000);
    } else {
      startPolling(1500);
    }
  }, [isDone]);

  if (!data?.store) {
    return null;
  }
  const filesWithErrors = data.store.listFiles.filter(
    ({ status }) => status === FileStatus.ERROR
  );

  const processingFiles = data.store.listFiles.filter(
    (f) =>
      f.status === FileStatus.PROCESSING ||
      f.status === FileStatus.WAIT_FOR_PROCESSING
  );

  return (
    <Modal>
      <Box
        spaceVertical="xxlarge"
        spaceHorizontal="xlarge"
        style={{ position: "relative", flex: 1 }}
      >
        {isDone ? (
          <Box
            spaceVertical="large"
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <AHText
                spaceBottom="small"
                type="title-big"
                color="primary"
                style={{ textAlign: "center" }}
              >
                Klart!
              </AHText>
            </Box>
            {filesWithErrors.length > 0 ? (
              <Box style={{ flex: 1, width: "100%" }}>
                <AHText
                  spaceLeft="large"
                  spaceBottom="xsmall"
                  type="title-small"
                  color="negative"
                >
                  Vissa filer kunde inte laddas upp
                </AHText>
                <Box spaceLeft="large" spaceBottom="large">
                  <AHText type="body" color="washedText">
                    {filesWithErrors.length} fil
                    {filesWithErrors.length === 1 ? "" : "er"} kunde inte laddas
                    upp.
                  </AHText>

                  <Box>
                    {filesWithErrors.map(({ id, name, type, errorReason }) => (
                      <AHText key={id} type="body" color="washedText">
                        {name}.{type?.toLowerCase()} -{" "}
                        {errorReasonDescription(errorReason)}
                      </AHText>
                    ))}
                  </Box>
                </Box>
              </Box>
            ) : null}
            {processingFiles.length > 0 ? (
              <Box style={{ flex: 1, width: "100%" }}>
                <AHText
                  spaceLeft="large"
                  spaceBottom="xsmall"
                  type="title-small"
                  color="primary"
                >
                  Filerna behandlas
                </AHText>
                <Box spaceLeft="large" spaceBottom="large">
                  <AHText type="body" color="washedText">
                    {processingFiles.length} fil
                    {processingFiles.length === 1 ? "" : "er"} behandlas.
                  </AHText>
                  <AHText type="body" color="washedText">
                    Filerna finns nu tillgängliga i appen! Men det finns
                    fortfarande lite kvar att göra. Detta kan ta några minuter,
                    men du kan lämna sidan om du vill.
                  </AHText>

                  <AHText type="body" color="washedText">
                    Vi söker söker igenom filerna för att innehållet i filerna
                    ska vara sökbart samt för att kunna visa filerna på
                    Storecast.
                  </AHText>
                </Box>
              </Box>
            ) : null}
            <AHButton
              style={{ width: 200 }}
              title="Ok"
              themeType="primary"
              onPress={() => {
                props.onDismiss();
              }}
            />
          </Box>
        ) : (
          <Box
            spaceVertical="large"
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <AHText spaceTop="large" type="title-big" color="primary">
              Laddar upp filer
            </AHText>
            <Lottie animationData={walk} />
            <Box spaceLeft="large" spaceBottom="large">
              <AHText
                style={{ textAlign: "center" }}
                type="body"
                color="washedText"
              >
                {Math.round(
                  props.uploadProgress * 100 * (1 - percentPendingFiles * 0.5)
                )}
                %
              </AHText>
              <Box spaceTop="large">
                <ProgressIndicator
                  percent={
                    props.uploadProgress * 100 * (1 - percentPendingFiles * 0.5)
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

import React from "react";
import { View, ViewProps } from "react-native";
import { AHText } from "../Text/AHText";

export const InputDescription = ({
  title,
  subTitle,
  ...props
}: {
  title?: string;
  subTitle?: string;
} & ViewProps) => (
  <View {...props}>
    {title ? (
      <AHText
        type="subtitle"
        style={{
          marginTop: 20,
          color: "rgb(160,160,163)",
        }}
      >
        {title}
      </AHText>
    ) : null}
    {subTitle ? (
      <AHText
        type="body"
        style={{
          marginTop: 5,
          marginBottom: 10,
        }}
      >
        {subTitle}
      </AHText>
    ) : null}
  </View>
);

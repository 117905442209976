import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  split,
} from "@apollo/client";
import { Native, Browser } from "sentry-expo";

import { getMainDefinition } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import ApolloLinkTimeout from "apollo-link-timeout";
import { createCache } from "./cache";
import { createWSLink } from "./link";

export type GraphError = "UNAUTHENTICATED" | "NO_STORE";
export function createApolloClient(config: {
  baseUrl: string;
  baseWSUrl: string;
  getAuthSession: () => Promise<{ authToken: string; storeId?: string }>;
  onRefreshToken: () => Promise<{ authToken: string }>;
  onError: (error: GraphError) => void;
  addBreadcrumb: (breadcrumb: Native.Breadcrumb | Browser.Breadcrumb) => void;
  timeout?: number;
}) {
  const setOriginalTime = setContext((_op, previousContext) => {
    return {
      ...previousContext,
      time: Math.floor(Date.now() / 1000),
    };
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 200,
      max: 1500,
    },
    attempts: (count, operation, error) => {
      const originalTime = operation.getContext().time;
      const now = Date.now() / 1000;
      return count < 4 && originalTime > now - 40; // 40 sec
    },
  });

  const httpLink = createUploadLink({
    uri: config.baseUrl + "/graphql",
  }) as unknown as ApolloLink;

  const cs = setContext(({ query }, prev) => {
    const definition = getMainDefinition(query);
    if (
      definition.kind === "OperationDefinition" &&
      definition.operation === "query"
    ) {
      return {
        ...prev,
        timeout: -1,
      };
    }
    return prev;
  });

  const timeoutLink = new ApolloLinkTimeout(config.timeout ?? 15000);
  const httpWithTimeoutLink = timeoutLink.concat(httpLink);

  const { wsLink } = createWSLink(config.baseWSUrl, config.getAuthSession);

  const networkLink = split(
    // split based on operation type
    ({ query, operationName }) => {
      config.addBreadcrumb({
        category: "gql-operation",
        type: "query",
        message: operationName,
        level: Browser.Severity.Info,
        data: {
          operationName,
        },
      });

      const definition = getMainDefinition(query);

      console.log("🌐 " + (definition as any)?.operation + " " + operationName);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpWithTimeoutLink
  );

  const authLink = setContext(async (op) => {
    config.addBreadcrumb({
      type: "gql",
      category: "gql.request",
      data: {
        operationName: op.operationName,
      },
    });
    const session = await config.getAuthSession();
    return {
      headers: {
        authorization: `Bearer ${session.authToken}`,
        "x-storesprint-store-id": session.storeId,
      },
    };
  });

  const errorLink = onError((f) => {
    const { networkError, operation, forward } = f;
    const definition = getMainDefinition(operation.query);

    console.log("OperationError:", f.operation.operationName);
    console.log(networkError, f.graphQLErrors);
    if ((networkError as any)?.statusCode === 502) {
      console.log("server unavail");
    } else {
      console.log(networkError, f.graphQLErrors);
    }

    if (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    ) {
      return forward(operation);
    } else {
      config.addBreadcrumb({
        type: "gql",
        category: "gql.fail",
        data: {
          operationName: f.operation.operationName,
          response: f.response,
          variables: JSON.stringify(f.operation.variables, null, 2),
          errors: JSON.stringify(f.graphQLErrors, null, 2),
          networkError: JSON.stringify(f.networkError, null, 2),
          networkStatusCode:
            (networkError && (networkError as any)?.statusCode) ?? undefined,
        },
      });
      const errorCodes = f.graphQLErrors?.map((e) => e?.extensions?.code) ?? [];

      if (errorCodes.includes("UNAUTHENTICATED")) {
        config.onError("UNAUTHENTICATED");
      }
      if (
        (networkError && (networkError as any).statusCode === 401) ||
        errorCodes.includes("OLD_TOKEN")
      ) {
        console.log("Refreshing token");

        return fromPromise(
          config
            .onRefreshToken()
            .then((at) => {
              return at?.authToken;
            })
            .catch(() => {
              console.log("FAILED TO REFRESH");
              return false;
            })
        )
          .filter((value) => Boolean(value))
          .flatMap((accessToken) => {
            const oldHeaders = operation.getContext().headers;
            if (!accessToken) {
              throw new Error("invalid state");
            }
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken}`,
              },
            });

            return forward(operation);
          });
      } else if (errorCodes.includes("STORE_NOT_FOUND")) {
        config.onError("NO_STORE");
      } else if (networkError) {
      } else {
      }
    }
  });

  const cache = createCache();
  const client = new ApolloClient({
    assumeImmutableResults: true,
    link: from([
      setOriginalTime,
      retryLink,
      errorLink,
      authLink,
      cs,
      networkLink,
    ]),
    cache,
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        partialRefetch: true,
        nextFetchPolicy(lastFetchPolicy) {
          if (
            lastFetchPolicy === "cache-and-network" ||
            lastFetchPolicy === "network-only"
          ) {
            return "cache-first";
          }
          return lastFetchPolicy;
        },
      },
    },
  });

  client.onClearStore(async () => {
    wsLink.client.terminate();
  });

  return { client, cache };
}

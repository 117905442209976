import { Feather } from "@expo/vector-icons";
import React, { PropsWithChildren, useMemo } from "react";
import {
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TouchableOpacityProps,
  ViewProps,
  StyleSheet,
  View,
  GestureResponderEvent,
} from "react-native";

import { ColorType, useTheme } from "../AHTheme/AHTheme";
import { Box } from "../AHTheme/Box";
import { useLayoutContext } from "../LayoutProvider";

interface AHListItemPropsTouchable extends TouchableOpacityProps {
  isTopItem?: boolean;
  isBottomItem?: boolean;
  onRequestDelete?: ((event: GestureResponderEvent) => void) | undefined;
  touchable?: true | undefined | null;
}

interface AHListItemPropsNonTouchable extends ViewProps {
  isTopItem?: boolean;
  isBottomItem?: boolean;
  onRequestDelete?: ((event: GestureResponderEvent) => void) | undefined;
  touchable: false;
}

export type AHListItemProps =
  | AHListItemPropsTouchable
  | AHListItemPropsNonTouchable;

const AHListItemStyle = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export const useAHListItemStyle = () => {
  const theme = useTheme();
  const { radius, space } = useLayoutContext();
  return useMemo(
    () => ({
      container: [
        AHListItemStyle.container,
        {
          padding: space.small,
          paddingVertical: space.medium,
          backgroundColor: theme.surface,
        },
      ],
      topItem: [
        {
          borderTopRightRadius: radius.medium,
          borderTopLeftRadius: radius.medium,
        },
      ],
      bottomItem: [
        {
          borderBottomRightRadius: radius.medium,
          borderBottomLeftRadius: radius.medium,
        },
      ],
    }),
    [radius.medium, space.medium, space.small, theme.surface]
  );
};

export const AHListItem = React.memo(
  React.forwardRef<TouchableOpacity, PropsWithChildren<AHListItemProps>>(
    (props, ref) => {
      const liStyle = useAHListItemStyle();

      const style: StyleProp<ViewStyle> = useMemo(() => {
        const s: StyleProp<ViewStyle> = [
          AHListItemStyle.container,
          liStyle.container,
        ];
        if (props.isTopItem) {
          s.push(liStyle.topItem);
        }
        if (props.isBottomItem) {
          s.push(liStyle.bottomItem);
        }
        if (props.style) {
          s.push(props.style);
        }
        return s;
      }, [
        liStyle.container,
        liStyle.topItem,
        liStyle.bottomItem,
        props.isTopItem,
        props.isBottomItem,
        props.style,
      ]);

      const innerChild = useMemo(() => {
        if (props.touchable === false) {
          return (
            <View ref={ref} {...props} style={style}>
              {props.children}
            </View>
          );
        } else {
          return (
            <TouchableOpacity ref={ref} {...props} style={style}>
              {props.children}
            </TouchableOpacity>
          );
        }
      }, [props, ref, style]);

      return innerChild;
    }
  )
);

type ArrowProps = {
  color?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
};

export const Arrow = ({ color, style }: ArrowProps) => {
  const theme = useTheme();
  return (
    <Feather
      name="chevron-right"
      size={20}
      style={style}
      color={color ?? theme.washedText}
    />
  );
};

export const Plus = ({ color, style, size }: ArrowProps) => {
  const theme = useTheme();
  return (
    <Feather
      name="plus"
      size={size ?? 17}
      style={style}
      color={color ?? theme.primary}
    />
  );
};

export const Cross = ({ color, style, size }: ArrowProps) => {
  const theme = useTheme();
  return (
    <Feather
      name="x"
      size={size ?? 17}
      style={style}
      color={color ?? theme.darkText}
    />
  );
};

type CheckProps = {
  selected?: boolean;
  color?: string;
};

export const Check = (props: CheckProps) => {
  const theme = useTheme();
  const selectedColor = props.color ?? theme.primary;
  return (
    <Feather
      name="check"
      size={20}
      style={{ paddingRight: 10 }}
      color={props.selected ? selectedColor : "transparent"}
    />
  );
};

type SelectDotProps = {
  selected: boolean;
  style?: StyleProp<ViewStyle>;
  selectedDotColor?: ColorType;
  backgroundColor?: ColorType;
  disabled?: boolean;
};

export const SelectDot = ({
  backgroundColor = "background",
  ...props
}: SelectDotProps) => {
  return (
    <Box
      color={props.selected ? props.selectedDotColor : backgroundColor}
      borderColor={props.selected ? props.selectedDotColor : "surfaceBorder"}
      style={[
        {
          width: 20,
          height: 20,
          borderRadius: 20,
          opacity: props.disabled ? 0.4 : 1,
          borderWidth: props.selected ? 0 : StyleSheet.hairlineWidth,
        },
        props.style,
      ]}
    />
  );
};

import React, { PropsWithChildren, useMemo } from "react";
import {
  LayoutChangeEvent,
  StyleProp,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { BoxProps } from "./BoxProps";
import { useBoxStyle } from "./useBoxStyle";

export type TouchableBoxProps = PropsWithChildren<
  BoxProps & {
    style?: StyleProp<ViewStyle>;
  } & Pick<
      TouchableOpacityProps,
      | "onPress"
      | "onPressIn"
      | "onPressOut"
      | "disabled"
      | "hitSlop"
      | "pressRetentionOffset"
      | "activeOpacity"
    >
>;

export const TouchableBox = React.forwardRef<
  TouchableOpacity,
  TouchableBoxProps
>(
  (
    {
      onPress,
      onPressIn,
      onPressOut,
      children,
      style,
      disabled,
      hitSlop,
      pressRetentionOffset,
      activeOpacity,
      ...props
    },
    ref
  ) => {
    const boxStyle = useBoxStyle(props);
    return (
      <TouchableOpacity
        ref={ref}
        pressRetentionOffset={pressRetentionOffset}
        onPress={onPress}
        hitSlop={hitSlop}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        disabled={disabled}
        activeOpacity={activeOpacity}
        style={[boxStyle, style]}
      >
        {children}
      </TouchableOpacity>
    );
  }
);

type _BoxProps = PropsWithChildren<
  BoxProps & {
    style?: StyleProp<ViewStyle>;
    pointerEvents?: ViewProps["pointerEvents"];
    onLayout?: (event: LayoutChangeEvent) => void;
  }
>;

export const Box = React.forwardRef<View, _BoxProps>(
  ({ children, style, onLayout, pointerEvents, ...props }, ref) => {
    const boxStyle = useBoxStyle(props);
    const s = useMemo(() => [boxStyle, style], [boxStyle, style]);
    return (
      <View
        pointerEvents={pointerEvents}
        ref={ref}
        onLayout={onLayout}
        style={s}
      >
        {children}
      </View>
    );
  }
);

import { ActivityIndicator, View } from "react-native";
import React, { ReactElement, useEffect, useRef } from "react";

import { NetworkStatus } from "@apollo/client";
import { captureException } from "../runtime/captureException";
import { useTheme } from "../cross-platform/AHTheme/AHTheme";

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * loading -> [loaded, error]
 * loaded -> [loading]
 * error -> [loadingWithError]
 * loadingWithError -> [loaded, error]
 */
enum AHQueryHandlerState {
  loading,
  loaded,
  error,
  loadingWithError,
}

export type AHQueryHandlerProps = {
  error?: Error;
  renderLoading?: () => ReactElement | null;
  renderError: (error: Error, isLoading: boolean) => ReactElement | null;
  renderLoaded: () => ReactElement | null;
  networkStatus: NetworkStatus | boolean;
};

export const AHQueryHandlerLoading = () => {
  const theme = useTheme();
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <ActivityIndicator color={theme.washedText} size="small" />
    </View>
  );
};

const statusForProps = (
  networkStatus: NetworkStatus | boolean,
  error?: Error,
  lastError?: Error
) => {
  if (
    networkStatus === NetworkStatus.poll ||
    networkStatus === NetworkStatus.refetch
  ) {
    return lastError
      ? AHQueryHandlerState.loadingWithError
      : AHQueryHandlerState.loaded;
  }
  if (networkStatus === NetworkStatus.loading || networkStatus === true) {
    return lastError
      ? AHQueryHandlerState.loadingWithError
      : AHQueryHandlerState.loading;
  }
  if (error) {
    return AHQueryHandlerState.error;
  }
  return AHQueryHandlerState.loaded;
};

function AHQueryHandlerC(props: AHQueryHandlerProps) {
  const lastError = usePrevious(props.error);

  const state: AHQueryHandlerState = statusForProps(
    props.networkStatus,
    props.error,
    lastError
  );

  useEffect(() => {
    if (props.error) {
      captureException(props.error);
    }
  }, [props.error]);
  switch (state) {
    case AHQueryHandlerState.loading:
      return props.renderLoading ? (
        props.renderLoading()
      ) : (
        <AHQueryHandlerLoading />
      );
    case AHQueryHandlerState.loaded:
      return props.renderLoaded();
    case AHQueryHandlerState.loadingWithError:
      return props.renderError(lastError as Error, true);
    case AHQueryHandlerState.error:
      return props.renderError(props.error as Error, false);
  }
}

export const AHQueryHandler = React.memo(AHQueryHandlerC);

import { AHText, AHTextFonts, AHTextProps } from "../Text";
import { StyleProp, TextStyle } from "react-native";

import { Box } from "../AHTheme/Box";
import React from "react";
import { useScaleFactor } from "../AHScaleFactor";

export const useWidgetTitleHeight = () => {
  return 25 * useScaleFactor();
};
const AHWidgetTitleC = ({
  color,
  enableTextShadow,
  style,
  ...rest
}: {
  style?: StyleProp<TextStyle>;
  enableTextShadow?: boolean;
} & AHTextProps) => {
  const height = useWidgetTitleHeight();
  return (
    <Box style={{ flexDirection: "column", height }}>
      <AHText
        type="subtitle"
        allowFontScaling={false}
        spaceTop="small"
        spaceHorizontal="medium"
        color={color ?? "darkerText"}
        font={AHTextFonts.BOLDER}
        style={[
          enableTextShadow
            ? {
                backgroundColor: "transparent",
                textShadowOffset: { width: 0, height: 0 },
                textShadowRadius: 3,
                textShadowColor: "black",
              }
            : undefined,

          style,
        ]}
        numberOfLines={1}
        {...rest}
      />
    </Box>
  );
};
export const AHWidgetTitle = React.memo(AHWidgetTitleC);

import React from "react";
import { Image } from "react-native";
import { FileType } from "../globalTypes";

export function FileIcon(props: { filetype: FileType | null; size?: number }) {
  const size = props.size ?? 30;
  switch (props.filetype) {
    case FileType.DOC:
    case FileType.DOCX:
      return (
        <Image
          source={require("./images/text.png")}
          style={{ height: size, width: size }}
        />
      );
    case FileType.PDF:
      return (
        <Image
          source={require("./images/pdf.png")}
          style={{ height: size, width: size }}
        />
      );
    case FileType.XLS:
    case FileType.XLSX:
      return (
        <Image
          source={require("./images/excel.png")}
          style={{ height: size, width: size }}
        />
      );
  }
  return (
    <Image
      source={require("./images/pdf.png")}
      style={{ height: size, width: size }}
    />
  );
}

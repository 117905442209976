import React from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  ViewProps,
} from "react-native";
import { Box } from "../AHTheme/Box";

import { AHText, AHTextProps } from "../Text/AHText";

export const AHSectionHeaderTitle = (props: AHTextProps) => (
  <AHText type="title" style={[props.style]}>
    {props.children}
  </AHText>
);
export const AHSectionHeaderDetails = (props: AHTextProps) => (
  <AHText type="detail" style={[{ paddingTop: 2 }, props.style]}>
    {props.children}
  </AHText>
);

type AHSectionHeaderProps = {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  direction?: "row" | "column";
};
export const AHSectionHeader = (props: AHSectionHeaderProps) => {
  const direction = props.direction ?? "row";
  return (
    <Box
      spaceTop="large"
      spaceBottom="small"
      spaceLeft="xxsmall"
      style={[
        {
          flexDirection: direction,
          alignItems: direction === "row" ? "center" : "flex-start",
        },
        props.style,
      ]}
    >
      {props.children}
    </Box>
  );
};

export const AHListSection: React.FC<ViewProps> = (props) => {
  return <View {...props} style={[styles.section, props.style]} />;
};

const styles = StyleSheet.create({
  section: {
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#FFF",
  },
  sectionHeaderTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
});

import { CircleSize, useCircleSize } from "../LayoutProvider";
import React, { PropsWithChildren } from "react";

import { Box } from "./Box";
import { BoxProps } from "./BoxProps";
import { ViewStyle } from "react-native";
import { useBoxStyle } from "./useBoxStyle";

export function Circle(
  props: PropsWithChildren<
    Pick<BoxProps, "borderColor" | "color" | "shadow"> & {
      size: CircleSize;
      borderWidth?: number;
    } & { style?: ViewStyle }
  >
) {
  const { size, children, borderWidth, style, ...boxProps } = props;
  const boxStyles = useBoxStyle(boxProps);
  const absSize = useCircleSize(size);

  return (
    <Box
      style={[
        boxStyles,
        {
          flexDirection: "column",
          alignItems: "center",
          borderWidth,
          justifyContent: "center",
          width: absSize,
          height: absSize,
          borderRadius: Math.ceil(absSize / 2),
          ...style,
        },
      ]}
      children={children}
    />
  );
}

import React, { useEffect } from "react";
import { Box } from "arkh";

import { config } from "../config";
declare var AdobeDC: any;
export function PDFPreview(props: {
  url: string;
  searchQuery?: string;
  fileName?: string;
}) {
  const pdfUrl = props.url;
  useEffect(() => {
    if (pdfUrl) {
      var adobeDCView = new (AdobeDC as any).View({
        clientId: config.adobePDFPreviewClientId,
        divId: "adobe-pdf-preview",
        locale: "sv-SE",
      });
      adobeDCView
        .previewFile(
          {
            content: {
              location: {
                url: pdfUrl,
              },
            },
            metaData: { fileName: props.fileName },
          },
          {
            enableSearchAPIs: true,
            showDownloadPDF: false,
            enableFormFilling: false,
            showAnnotationTools: false,
            embedMode: "SIZED_CONTAINER",
          }
        )
        .then((adobeViewer: any) => {
          if (props.searchQuery) {
            adobeViewer.getAPIs().then((apis: any) => {
              apis
                .search(props.searchQuery || "")
                .then((searchObject: any) => searchObject.next())
                .catch((error: any) => console.log(error));
            });
          }
        });
    }
  }, [pdfUrl]);
  return (
    <Box cornerRadius="medium" style={{ overflow: "hidden" }}>
      <div style={{ height: 350 }} id="adobe-pdf-preview"></div>
    </Box>
  );
}

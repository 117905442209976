import React from "react";
import {
  AHButton,
  AHQueryHandler,
  AHQueryHandlerError,
  AHText,
  Box,
  TouchableBox,
} from "arkh";
import { Feather } from "@expo/vector-icons";
import "./files.css";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  SideBarFileDetailQuery,
  SideBarFileDetailQueryVariables,
  SideBarFileDetailQuery_store_file,
} from "./__generated__/SideBarFileDetailQuery";
import { formatter } from "./FileListItem/formatter";
import { PDFPreview } from "./PDFPreview";
import { FileIcon } from "./FileListItem/FileIcon";
import { FileStatus } from "./globalTypes";
import { useState } from "react";
import { DeleteFileModal } from "./DeleteFileModal";
import { ScrollView } from "react-native";

const DetailRow = (props: { desc: string; content: string }) => {
  return (
    <Box
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 3,
      }}
    >
      <Box>
        <AHText color="black">{props.desc}</AHText>
      </Box>
      <Box>
        <AHText>{props.content}</AHText>
      </Box>
    </Box>
  );
};

export const SideBar = (props: {
  fileId: string | null;
  searchQuery: string | null;
  onDismiss: () => void;
}) => {
  const open = props.fileId ? true : false;

  const { data, loading, refetch, error } = useQuery<
    SideBarFileDetailQuery,
    SideBarFileDetailQueryVariables
  >(
    gql`
      query SideBarFileDetailQuery($fileId: ID!) {
        store {
          file(id: $fileId) {
            id
            name
            createdAt
            url
            pdfUrl
            accessedBy {
              id
              name
              email
            }
            formattedSize
            lastAccessedAt
            accessedCount
            uploadedBy {
              id
              name
            }
            status
            type
            thumbnail {
              id
              size(minWidth: 100, minHeight: 100) {
                id
                url
                width
                height
              }
            }
          }
        }
      }
    `,
    {
      variables: props.fileId ? { fileId: props.fileId } : undefined,
      skip: !props.fileId,
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    }
  );

  const renderContent = () => {
    if (!data?.store.file) {
      return null;
    }
    return (
      <SidebarFile
        key={data.store.file.id}
        onDismiss={props.onDismiss}
        file={data.store.file}
        searchQuery={props.searchQuery ?? undefined}
      />
    );
  };

  return (
    <>
      <div
        style={{
          position: "fixed",

          minHeight: "100vh",
          width: 500,
          right: 0,
          transform: open ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.5s ease-in-out",
        }}
      >
        <Box color="surface" shadow="strong" style={{ height: "100vh" }}>
          <AHQueryHandler
            networkStatus={loading}
            error={error}
            renderLoaded={renderContent}
            renderError={(err, isLoading) => (
              <AHQueryHandlerError isLoading={isLoading} onPress={refetch} />
            )}
          />
        </Box>
      </div>
    </>
  );
};

function SidebarFile(props: {
  file: SideBarFileDetailQuery_store_file;
  searchQuery?: string;
  onDismiss: () => void;
}) {
  const [fileName, setFileName] = React.useState(props.file.name);
  const isDirty = fileName !== props.file.name;

  const [updateName, updateNameInfo] = useMutation(
    gql`
      mutation UpdateFileName($id: ID!, $name: String!) {
        store {
          file(id: $id) {
            update(input: { setName: $name }) {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: { id: props.file.id, name: fileName },
      refetchQueries: ["FilesScreenQuery", "FilesBrowserQuery"],
    }
  );

  const [df, sdf] = useState(false);

  return (
    <>
      {df ? (
        <DeleteFileModal
          fileId={props.file.id}
          fileName={props.file.name ?? ""}
          onDismiss={() => {
            sdf(false);
          }}
          onDeleted={() => {
            props.onDismiss();
          }}
          thumbnail={props.file.thumbnail?.size?.url ?? undefined}
        />
      ) : null}
      <ScrollView>
        <Box spaceTop="large" space="xxlarge">
          <Box
            spaceVertical="medium"
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <AHText type="title-big">Hantera fil</AHText>
            <Box style={{ flexDirection: "row" }}>
              <a
                style={{ textDecoration: "none" }}
                href={props.file.url}
                download={props.file.name}
              >
                <AHText color="primary">
                  <Feather
                    name="download"
                    size={24}
                    style={{ marginRight: 20 }}
                  />
                </AHText>
              </a>

              <TouchableBox
                onPress={() => {
                  sdf(true);
                }}
              >
                <AHText color="negative">
                  <Feather name="trash-2" size={24} />
                </AHText>
              </TouchableBox>
            </Box>
          </Box>

          <AHText spaceTop="xsmall" type="subtitle" color="washedText">
            Namn:
          </AHText>

          <Box style={{ flexDirection: "row" }} spaceTop="xsmall">
            <input
              type="text"
              style={{
                fontSize: 18,
                padding: 10,
                flex: 1,
                outline: "1px solid",
                borderRadius: 10,
                outlineColor: "lightgray",
                border: "none",
                backgroundColor: "none",
              }}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              maxLength={250}
              value={fileName ?? ""}
            />
          </Box>
          <Box spaceVertical="large">
            {props.file ? (
              <PDFPreview
                url={props.file?.pdfUrl}
                fileName={fileName ?? undefined}
                searchQuery={props.searchQuery ?? undefined}
              ></PDFPreview>
            ) : null}
          </Box>

          <Box
            color="background"
            cornerRadius="large"
            style={{ position: "relative", overflow: "hidden" }}
          >
            <div
              className="OpenFile"
              style={{
                cursor: "pointer",
                zIndex: 2,
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AHText type="title"> Öppna</AHText>
            </div>
            <Box
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
              space="small"
            >
              <FileIcon filetype={props.file.type} size={70} />
              <Box space="medium" style={{ flex: 1, justifyContent: "center" }}>
                <DetailRow desc="Storlek" content={props.file.formattedSize} />
                <DetailRow
                  desc="Tillagd"
                  content={formatter.format(new Date(props.file.createdAt))}
                />
                <DetailRow
                  desc="Uppladdad av"
                  content={props.file.uploadedBy?.name ?? ""}
                />
                <DetailRow
                  desc="Öppnad senast"
                  content={
                    props.file?.lastAccessedAt
                      ? formatter.format(new Date(props.file?.lastAccessedAt))
                      : "aldrig"
                  }
                />
                <DetailRow
                  desc="Redo att visas på Storecast"
                  content={
                    props.file.status === FileStatus.READY ? "Ja" : "Nej"
                  }
                />
              </Box>
            </Box>
            {props.file.accessedBy.length > 0 ? (
              <Box
                space="large"
                borderColor="surfaceBorder"
                style={{ borderTopWidth: 1 }}
              >
                <AHText spaceBottom="xxsmall" type="subtitle">
                  Öppnad
                </AHText>
                <AHText spaceBottom="xsmall" type="detail">
                  Filen är öppnad totalt {props.file.accessedCount} gånger av{" "}
                  {props.file.accessedBy.length} användare.
                </AHText>
                {props.file.accessedBy.map((af) => (
                  <Box
                    key={af.id}
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                    spaceTop="xxsmall"
                  >
                    <AHText color="darkerText">{af.name}</AHText>
                    <AHText>{af.email}</AHText>
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      </ScrollView>
      <div style={{ width: "100%" }}>
        <Box
          spaceTop="small"
          spaceBottom="medium"
          spaceHorizontal="xxlarge"
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <AHButton
            title="Stäng"
            onPress={props.onDismiss}
            style={{ minWidth: 150 }}
            themeType="default"
          />
          {isDirty ? (
            <Box spaceLeft="large">
              <AHButton
                loading={updateNameInfo.loading}
                title="Spara"
                style={{ minWidth: 150 }}
                onPress={() => {
                  updateName();
                }}
                themeType="primary"
              />
            </Box>
          ) : null}
        </Box>
      </div>
    </>
  );
}

import { Feather } from "@expo/vector-icons";

import React from "react";
import { StyleSheet, View } from "react-native";
import { useTheme } from "../AHTheme/AHTheme";
import { useLayoutContext } from "../LayoutProvider";
import { AHText } from "../Text";

const style = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
});
export const AHWidgetNoData = () => {
  const theme = useTheme();
  const size = useLayoutContext().circle.medium;
  return (
    <View style={[style.container]}>
      <View style={{ alignItems: "center", flexDirection: "column" }}>
        <AHText type="subtitle" style={[style.text]} numberOfLines={1}>
          No Data
        </AHText>
        <Feather name="activity" color={theme.washedText} size={size} />
      </View>
    </View>
  );
};

import { useMemo } from "react";
import { useScaleFactor } from "./AHScaleFactor";

export const layoutConfig: Layout = {
  radius: {
    none: 0,
    xsmall: 3,
    small: 5,
    medium: 10,
    large: 15,
  },
  circle: {
    dot: 6,
    badge: 9,
    small: 16,
    smallMedium: 24,
    medium: 32,
    large: 40,
    xlarge: 60,
  },
  icon: {
    detail: 8,
    body: 12,
    listItem: 15,
    small: 19,
    button: 22,
    medium: 30,
  },
  space: {
    none: 0,
    widget: 5,
    xxsmall: 2,
    xsmall: 5,
    small: 10,
    medium: 15,
    large: 20,
    xlarge: 30,
    xxlarge: 45,
  },
  height: {
    button: 50,
    headerItem: 30,
  },
  text: {
    detail: 9,
    body: 12,
    smallTitle: 15,
    title: 19,
    largeTitle: 30,
    xlargeTitle: 30,
  },
};

export type Height = "button" | "headerItem";
export type Radius = "xsmall" | "small" | "medium" | "large" | "none";
export type Space =
  | "none"
  | "widget"
  | "xxsmall"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

export type TextSize =
  | "detail"
  | "body"
  | "smallTitle"
  | "title"
  | "largeTitle"
  | "xlargeTitle";

export type CircleSize =
  | "dot"
  | "smallMedium"
  | "badge"
  | "small"
  | "medium"
  | "large"
  | "xlarge";

export type IconSize =
  | "detail"
  | "body"
  | "small"
  | "listItem"
  | "button"
  | "medium";

export type Layout = {
  circle: Record<CircleSize, number>;
  radius: Record<Radius, number>;
  space: Record<Space, number>;
  text: Record<TextSize, number>;
  icon: Record<IconSize, number>;
  height: Record<Height, number>;
};

export function useLayoutContext() {
  return layoutConfig;
}

export function useCircleSize(key: keyof Layout["circle"]) {
  const ctx = useLayoutContext();
  const sf = useScaleFactor();
  return useMemo(() => Math.ceil(ctx.circle[key] * sf), [ctx.circle, key, sf]);
}

export function useIconSize(key: keyof Layout["icon"]) {
  const ctx = useLayoutContext();
  return Math.floor(ctx.icon[key] * useScaleFactor());
}

export function useTextSize(key: keyof Layout["text"]) {
  const ctx = useLayoutContext();
  return Math.floor(ctx.text[key] * useScaleFactor());
}

export function useSpaceSize(key: keyof Layout["space"]) {
  const ctx = useLayoutContext();
  return Math.floor(ctx.space[key] * useScaleFactor());
}

export function useRadiusSize(key: keyof Layout["radius"]) {
  const ctx = useLayoutContext();
  return Math.floor(ctx.radius[key] * useScaleFactor());
}

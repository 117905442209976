import { createClient, CloseCode } from "graphql-ws";
import { GraphQLWsLink } from "./sublink";
const enum SubscriptionStatus {
  init = "init",
  connected = "connected",
  closed = "closed",
  connecting = "connecting",
  refetching = "refetching",
}
export function createWSLink(
  baseUrl: string,
  getAuthToken: () => Promise<{ authToken: string; storeId?: string }>,
  resetStoreWhenReconnected?: () => Promise<any>
) {
  let subscriptionStatus: SubscriptionStatus = SubscriptionStatus.init;
  const subscribeFns: ((status: SubscriptionStatus) => void)[] = [];

  const setSubscriptionStatus = (status: SubscriptionStatus) => {
    if (subscriptionStatus !== status) {
      console.log("subscription status", status);
      subscriptionStatus = status;
      subscribeFns.forEach((fn) => fn(status));
    }
  };

  const subscribeToStatus = (
    onNewStatus: (newStatus: SubscriptionStatus) => any
  ) => {
    subscribeFns.push(onNewStatus);
    const len = subscribeFns.length;
    return () => {
      delete subscribeFns[len - 1];
    };
  };

  let activeSocket: WebSocket | undefined;
  let timedOut: ReturnType<typeof setTimeout> | undefined;
  let currentLatency: number | undefined;
  let lastLatency: number | undefined;
  let lastPing = Date.now();

  const wsLink = new GraphQLWsLink(
    createClient({
      lazyCloseTimeout: 10000,
      url: baseUrl + "/sub",
      keepAlive: 5000,
      retryAttempts: 1000,
      connectionAckWaitTimeout: 3000,
      on: {
        connecting: () => {
          setSubscriptionStatus(SubscriptionStatus.connecting);
        },

        connected: (socket: any) => {
          setSubscriptionStatus(SubscriptionStatus.connected);
          if (
            activeSocket &&
            subscriptionStatus !== SubscriptionStatus.refetching &&
            resetStoreWhenReconnected
          ) {
            setSubscriptionStatus(SubscriptionStatus.refetching);
            // reconnect
            // refetch all queries
            resetStoreWhenReconnected().finally(() => {
              setSubscriptionStatus(SubscriptionStatus.connected);
            });
          } else {
            setSubscriptionStatus(SubscriptionStatus.connected);
          }
          activeSocket = socket;
        },
        closed: (event: any) => {
          console.log("closed");
          lastLatency = undefined;
          setSubscriptionStatus(SubscriptionStatus.closed);
          if (event?.code === CloseCode.Forbidden) {
          }
        },
        ping: (received) => {
          if (!received && timedOut === undefined) {
            lastPing = Date.now();
            timedOut = setTimeout(() => {
              if (activeSocket?.readyState === WebSocket.OPEN) {
                setSubscriptionStatus(SubscriptionStatus.closed);
                activeSocket?.close(4408, "Request Timeout");
              }
            }, 6000); // wait 3 seconds for the pong and then close the connection
          }
        },

        pong: (received) => {
          if (received && timedOut) {
            const latency = Math.max(Math.min(Date.now() - lastPing, 5000), 0);
            lastLatency = currentLatency;
            currentLatency =
              ((currentLatency ?? latency) +
                (lastLatency ?? latency) +
                latency) /
              3;

            clearTimeout(timedOut);
            timedOut = undefined;
          } // pong is received, clear connection close timeout
        },
      },
      connectionParams: async () => {
        console.log("waiting for connection params");
        const session = await getAuthToken();
        console.log("params recived");

        return {
          authToken: session.authToken,
          storeId: session.storeId,
        };
      },
    })
  );

  return { wsLink, currentLatency, subscribeToStatus, subscriptionStatus };
}

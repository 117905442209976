import Animated, {
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { FlatList, Platform, View } from "react-native";
import React, { PropsWithChildren, useCallback, useRef } from "react";

import { useRadiusSize } from "../LayoutProvider";

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList) as any;

export const PageWrapper = ({
  children,
  index,
  y,
  length,

  height,
}: PropsWithChildren<{
  index: number;
  y: Animated.SharedValue<number>;
  length: number;
  height: number;
}>) => {
  const inputRange = Array(length + 1)
    .fill(0)
    .map((_, index) => index * height);
  const outputRange = Array(length + 1)
    .fill(0.6)
    .map((_, i) => (i === index ? 1 : 0.8));
  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: interpolate(y.value, inputRange, outputRange),
        },
      ],
    };
  });
  const borderRadius = useRadiusSize("medium");
  return (
    <Animated.View
      style={[
        {
          width: "100%",
          height,
          borderRadius,
          overflow: "hidden",
        },
        animatedStyles,
      ]}
    >
      {children}
    </Animated.View>
  );
};

function AHPagerC<T extends { id: string }>(props: {
  height: number;
  data: T[];
  renderPage: (item: T, index: number) => React.ReactNode;
}) {
  const y = useSharedValue(0);
  const ref = useRef();
  const getItemLayout = useCallback(
    (_: any, index: number) => ({
      length: props.height,
      offset: props.height * index,
      index,
    }),
    [props.height]
  );

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <PageWrapper
          key={item.id}
          height={props.height}
          index={index}
          length={props.data.length}
          y={y}
        >
          {props.renderPage(item, index)}
        </PageWrapper>
      );
    },
    [props, y]
  );

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (e) => {
      y.value = e.contentOffset.y;
    },
  });

  return (
    <AnimatedFlatList
      ref={ref}
      getItemLayout={getItemLayout}
      onScroll={scrollHandler}
      showsVerticalScrollIndicator={false}
      pagingEnabled
      initialNumToRender={1}
      showsHorizontalScrollIndicator={false}
      bounces={false}
      data={props.data}
      scrollEventThrottle={16}
      scrollEnabled={props.data.length > 1}
      renderItem={renderItem}
    />
  );
}

function AHPagerWebC<T extends { id: string }>(props: {
  height: number;
  data: T[];
  renderPage: (item: T, index: number) => React.ReactNode;
}) {
  const borderRadius = useRadiusSize("medium");

  const [activeIndex, _setActiveIndex] = React.useState(0);
  const renderItem = useCallback(
    (item, index) => {
      return (
        <View
          style={[
            {
              width: "100%",
              height: props.height,
              borderRadius,
              overflow: "hidden",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: activeIndex === index ? 1 : 0,
            },
          ]}
        >
          {props.renderPage(item, index)}
        </View>
      );
    },
    [activeIndex, borderRadius, props]
  );

  return (
    <View style={{ flex: 1, position: "relative" }}>
      {props.data.map((d, i) => renderItem(d, i))}
    </View>
  );
}

export const AHPager =
  Platform.OS === "web" ? AHPagerWebC : React.memo(AHPagerC);

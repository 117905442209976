import React, { useState } from "react";
import { useQuery, gql, NetworkStatus } from "@apollo/client";
import {
  AHQueryHandler,
  AHQueryHandlerError,
  AHText,
  Box,
  TouchableBox,
} from "arkh";
import {
  FilesScreenQuery,
  FilesScreenQueryVariables,
} from "./__generated__/FilesScreenQuery";
import { FlatList } from "react-native";
import { Feather } from "@expo/vector-icons";
import { FileListItem } from "./FileListItem";
import { FilesBrowserQuery } from "./__generated__/FilesBrowserQuery";
import { FileConnectionSortField } from "./globalTypes";
import "./files.css";
import { useDebounce } from "use-debounce";

export function FilesList(props: {
  openFile: (fileId: string, query: string) => void;
  clearFile: () => void;
  openFileId: string | null;
}) {
  const [query, setQuery] = useState<string>("");
  const [debouncedQuery] = useDebounce(query, 650);
  const [orderBy, setOrderBy] = useState<FileConnectionSortField>(
    FileConnectionSortField.CREATED_AT
  );

  const fileId = props.openFileId;

  const browseData = useQuery<FilesBrowserQuery>(
    gql`
      query FilesBrowserQuery(
        $orderBy: FileConnectionSortField!
        $after: String
      ) {
        store {
          id
          files(first: 50, orderBy: $orderBy, after: $after) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
                url
                createdAt
                type
                status
              }
            }
          }
        }
      }
    `,
    {
      variables: { orderBy },
      skip: !(debouncedQuery === "" || query === ""),
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    }
  );

  const searchData = useQuery<FilesScreenQuery, FilesScreenQueryVariables>(
    gql`
      query FilesScreenQuery($query: String!) {
        store {
          id
          fileSearch(query: $query) {
            id
            hits {
              id
              textHighlights {
                isHighlighted
                text
              }
              nameHighlights {
                isHighlighted
                text
              }
              file {
                id
                name
                url
                createdAt
                type
                status
              }
            }
          }
        }
      }
    `,
    {
      variables: { query: debouncedQuery },
      skip: debouncedQuery === "" || query === "",
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    }
  );

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        props.clearFile();
      }}
    >
      <Box space="xlarge">
        <Box
          style={{
            alignSelf: "center",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            maxWidth: 800,
            alignItems: "center",
          }}
        >
          <Feather
            name="search"
            size={24}
            style={{ left: 25, position: "absolute", zIndex: 10 }}
          />
          <input
            className="search"
            style={{
              width: "100%",
              height: 70,
              fontSize: 18,
              border: "none",
              textIndent: 60,
              borderRadius: 60,
            }}
            placeholder="Sök bland butikens filer"
            type="search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Box>

        <Box spaceBottom="medium" spaceTop="xlarge">
          <Box
            borderColor="surfaceBorder"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 1,
            }}
          >
            <TouchableBox
              onPress={() => setOrderBy(FileConnectionSortField.NAME)}
              spaceBottom="small"
            >
              <AHText type="title-small" color="washedText">
                Filnamn{" "}
                {orderBy === FileConnectionSortField.NAME ? (
                  <Feather
                    name="arrow-down"
                    style={{ marginLeft: 10 }}
                    size={18}
                  />
                ) : null}
              </AHText>
            </TouchableBox>
            <TouchableBox
              onPress={() => setOrderBy(FileConnectionSortField.CREATED_AT)}
            >
              <AHText type="title-small" color="washedText">
                Senast tillagd{" "}
                {orderBy === FileConnectionSortField.CREATED_AT ? (
                  <Feather
                    name="arrow-down"
                    style={{ marginLeft: 10 }}
                    size={18}
                  />
                ) : null}
              </AHText>
            </TouchableBox>
          </Box>
        </Box>
        {query === "" || debouncedQuery === "" ? (
          <AHQueryHandler
            error={browseData.error}
            networkStatus={browseData.loading && !browseData.data}
            renderError={(_error, isLoading) => (
              <AHQueryHandlerError
                isLoading={isLoading}
                onPress={searchData.refetch}
              />
            )}
            renderLoaded={() => (
              <FlatList
                data={browseData.data?.store.files.edges ?? []}
                extraData={fileId}
                style={{ width: "100%", flex: 1 }}
                onEndReachedThreshold={0.01}
                onEndReached={(e) => {
                  if (
                    browseData.networkStatus === NetworkStatus.ready &&
                    browseData.data?.store.files.pageInfo.hasNextPage &&
                    browseData.data.store.files.pageInfo.endCursor
                  ) {
                    /*  browseData.fetchMore({
                  variables: {
                    after: browseData.data.store.files.pageInfo.endCursor,
                  },
                }); */
                  }
                }}
                renderItem={({ item }) => {
                  return (
                    <FileListItem
                      open={item.node.id === props.openFileId}
                      file={item.node}
                      onPress={() => {
                        props.openFile(item.node.id, "");
                      }}
                      textHighlights={[]}
                      nameHighlights={[
                        { isHighlighted: false, text: item.node.name ?? "" },
                      ]}
                    />
                  );
                }}
              />
            )}
          />
        ) : (
          <AHQueryHandler
            error={searchData.error}
            networkStatus={
              searchData.loading &&
              !(
                searchData.data?.store.fileSearch.hits ??
                searchData.previousData?.store.fileSearch.hits
              )
            }
            renderError={(_error, isLoading) => (
              <AHQueryHandlerError
                isLoading={isLoading}
                onPress={searchData.refetch}
              />
            )}
            renderLoaded={() => (
              <FlatList
                extraData={fileId}
                data={
                  searchData.data?.store.fileSearch.hits ??
                  searchData.previousData?.store.fileSearch.hits
                }
                renderItem={({ item }) => {
                  return (
                    <FileListItem
                      key={item.id}
                      open={item.file.id === props.openFileId}
                      file={item.file}
                      onPress={() => {
                        props.openFile(item.file.id, query);
                      }}
                      textHighlights={item.textHighlights}
                      nameHighlights={item.nameHighlights}
                    />
                  );
                }}
              />
            )}
          />
        )}
      </Box>
    </div>
  );
}

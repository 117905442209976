import { Platform, ViewStyle } from "react-native";

const ios: { [key in AHShadowStyleIntensity]: ViewStyle } = {
  WEAK: {
    shadowColor: "#CCC",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
  MEDIUM: {
    shadowColor: "#CCC",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 6,
  },
  STRONG: {
    shadowColor: "#CCC",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.45,
    shadowRadius: 25,
  },
};

const android: { [key in AHShadowStyleIntensity]: ViewStyle } = {
  WEAK: { elevation: 3 },
  MEDIUM: { elevation: 5 },
  STRONG: { elevation: 7 },
};

export const AHShadowStyle = Platform.OS === "ios" ? ios : android;

type AHShadowStyleIntensity = "WEAK" | "MEDIUM" | "STRONG";

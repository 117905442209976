import React, { useRef, useState } from "react";
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { AHButton, AHText, Box, TouchableBox } from "arkh";
import { UploadFileGetLink } from "./__generated__/UploadFileGetLink";
import axios from "axios";
import { FileType } from "./globalTypes";
import { Feather } from "@expo/vector-icons";
import { Image } from "react-native";
import { formatBytes } from "./formatBytes";

import { getFilesAsync } from "./getFilesAsync";
import { FileQuoataQuery } from "./__generated__/FileQuoataQuery";
import { UpploadModal } from "./UpploadModal";

function mimeToType(type: string): FileType | undefined {
  switch (type) {
    case "application/pdf":
      return FileType.PDF;
    case "application/msword":
      return FileType.DOC;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return FileType.DOCX;
    case "application/vnd.ms-excel":
      return FileType.XLS;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return FileType.XLSX;

    default:
      return undefined;
  }
}
function fileNameToMimeType(fn: string): string | undefined {
  const fileName = fn.toLowerCase();
  const vals = {
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  return Object.entries(vals).find(([k]) => fileName.endsWith(k))?.[1];
}

type ErrorReason = "size" | "type" | "tooMany";

const maxBatchSize = 10;

function reasonMessage(reason: ErrorReason): string {
  switch (reason) {
    case "size":
      return "Filen är för stor. Maximal filstorlek är 10MB.";
    case "tooMany":
      return (
        "Du har för många filer. Ladda upp max " +
        maxBatchSize +
        " filer per gång."
      );
    case "type":
      return "Filtypen är inte tillåten. Endast PDF, Word och Excel är tillåtna.";
  }
}

function validateFiles(files: File[], existingCount: number) {
  let filesWithErrors: { file: File; reason: ErrorReason }[] = [];
  let acceptedFiles: {
    file: File;
    name: string;
    progress: undefined;
    status: "idle";
  }[] = [];

  files
    .filter((f) => !f.name.startsWith(".")) // filter out hidden files

    .forEach((file) => {
      if (
        mimeToType(file.type) === undefined &&
        fileNameToMimeType(file.name) === undefined
      ) {
        filesWithErrors.push({ file, reason: "type" });
      } else if (file.size > 10000000) {
        filesWithErrors.push({ file, reason: "size" });
      } else if (acceptedFiles.length + existingCount > maxBatchSize) {
        filesWithErrors.push({ file, reason: "tooMany" });
      } else {
        const nameParts = file.name.split(".");
        nameParts.pop();
        acceptedFiles.push({
          file,
          name: nameParts.join(""),
          status: "idle",
          progress: undefined,
        });
      }
    });

  return { filesWithErrors, acceptedFiles };
}

function FileQuoataExceded() {
  return (
    <Box>
      <Box
        borderColor="surfaceBorder"
        cornerRadius="large"
        space="xxlarge"
        style={{
          borderStyle: "dashed",
          borderWidth: 2,
          backgroundColor: "#EAF2FC",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 150,
          overflow: "hidden",
        }}
      >
        <AHText color="darkerText" type="title">
          För många filer
        </AHText>
        <AHText color="darkerText" type="body">
          Filutrymmet är fullt. Radera filer för att kunna ladda upp nya.
        </AHText>
      </Box>
    </Box>
  );
}

export function FileUploadArea() {
  const quoataQuery = useQuery<FileQuoataQuery>(gql`
    query FileQuoataQuery {
      store {
        id
        filesQuota {
          quota
          used
        }
      }
    }
  `);
  const [files, setFiles] = useState<
    {
      file: File;
      progress: number | undefined;
      name: string;
      status: "idle" | "uploading" | "error";
    }[]
  >([]);
  const [filesWithErrors, setFilesWithErrors] = useState<
    { file: File; reason: ErrorReason }[]
  >([]);
  const [getUploadLink, linkStatus] = useMutation<UploadFileGetLink>(gql`
    mutation UploadFileGetLink($name: String, $type: FileType!) {
      store {
        generateUploadLink(type: $type, name: $name) {
          id
          url
        }
      }
    }
  `);

  const k = useApolloClient();

  const [uploadStatus, setUploadStatus] = useState<null | {
    [fileId: string]: {
      status: "uploading" | "fail" | "done";
      progress: number;
    };
  }>(null);

  const uploadFiles = async () => {
    await Promise.all(
      files.map(({ file, name }, i) => {
        const type =
          mimeToType(file.type) ?? mimeToType(fileNameToMimeType(file.name)!);
        if (!type) {
          throw new Error("Unsupported file type");
        }
        return getUploadLink({
          variables: { name, type },
        }).then((res) => {
          if (!res.data?.store.generateUploadLink.id) {
            throw new Error();
          }
          const id = res.data!.store.generateUploadLink.id;
          setUploadStatus((f) => ({
            ...f,
            [id]: {
              progress: 0,
              status: "uploading",
            },
          }));

          return axios
            .request({
              method: "PUT",
              url: res.data?.store.generateUploadLink.url!,
              data: file,
              headers: {
                "Content-Type": mimeToType(file.type)
                  ? file.type
                  : fileNameToMimeType(file.name)!,
              },

              onUploadProgress: (p) => {
                setUploadStatus((f) => ({
                  ...f,
                  [id]: {
                    progress: p.loaded / p.total,
                    status: "uploading",
                  },
                }));
              },
            })
            .then(() => {
              setUploadStatus((f) => ({
                ...f,
                [id]: {
                  progress: 1,
                  status: "done",
                },
              }));
            })
            .catch(() => {
              setUploadStatus((f) => ({
                ...f,
                [id]: {
                  progress: 1,
                  status: "fail",
                },
              }));
            });
        });
      })
    );

    setFilesWithErrors([]);
  };

  const [isDragingOver, setIsDragingOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {uploadStatus ? (
        <UpploadModal
          onDismiss={() => {
            k.refetchQueries({
              include: ["FilesScreenQuery", "FilesBrowserQuery"],
            });
            setUploadStatus(null);
            setFiles([]);
          }}
          pendingFileIds={Object.keys(uploadStatus)}
          uploadProgress={Object.values(uploadStatus).reduce(
            (prev, curr) => prev * curr.progress,
            1
          )}
        />
      ) : null}

      <Box
        spaceHorizontal="xlarge"
        style={{
          backgroundColor: files.length > 0 ? "#EAF2FC" : "none",
          position: "relative",
        }}
      >
        <AHText
          style={{
            alignContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          type="title-big"
          spaceVertical="large"
        >
          Filer
        </AHText>

        <AHText space="xsmall">
          {quoataQuery.data?.store.filesQuota.used} av max{" "}
          {quoataQuery.data?.store.filesQuota.quota} filer.
        </AHText>
        {quoataQuery.data?.store.filesQuota &&
        quoataQuery.data.store.filesQuota.used >=
          quoataQuery.data.store.filesQuota.quota ? (
          <FileQuoataExceded />
        ) : (
          <div
            onClick={() => {
              inputRef.current?.click();
            }}
            style={{ flex: 1, cursor: "pointer" }}
            onDrop={async (e) => {
              e.persist();
              const dropedFiles = await getFilesAsync(e.dataTransfer);

              const { acceptedFiles, filesWithErrors } = validateFiles(
                dropedFiles,
                files.length
              );

              setFilesWithErrors((otherFiles) => [
                ...otherFiles,
                ...filesWithErrors,
              ]);
              setFiles((f) => [...f, ...acceptedFiles]);
              setIsDragingOver(true);
              e.preventDefault();
            }}
            onDragEnter={() => {
              setIsDragingOver(true);
            }}
            onDragExit={() => {
              setIsDragingOver(false);
            }}
            onDragEnd={() => {
              setIsDragingOver(false);
            }}
            onDragLeave={() => {
              setIsDragingOver(false);
            }}
            onDragOver={(e) => {
              setIsDragingOver(true);
              e.preventDefault();
            }}
          >
            <Box
              borderColor="surfaceBorder"
              cornerRadius="large"
              space="xlarge"
              style={{
                borderStyle: "dashed",
                borderWidth: 2,
                backgroundColor: isDragingOver ? "#DEEBFC" : "#EAF2FC",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 100,
                overflow: "hidden",
              }}
            >
              <input
                ref={inputRef}
                onChange={(e) => {
                  const dropedFiles = e.target.files
                    ? Array.from(e.target.files)
                    : [];

                  const { acceptedFiles, filesWithErrors } = validateFiles(
                    dropedFiles,
                    files.length
                  );

                  setFilesWithErrors((otherFiles) => [
                    ...otherFiles,
                    ...filesWithErrors,
                  ]);
                  setFiles((f) => [...f, ...acceptedFiles]);
                }}
                type="file"
                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                multiple
                style={{ opacity: 0, position: "absolute" }}
                maxLength={10}
              />
              <Box
                style={{ flexDirection: "row", alignItems: "center" }}
                space="small"
              >
                <AHText type="title-small" color="primary" textAlign="center">
                  Klicka för att välja eller släpp filer här!
                </AHText>
                <AHText color="primary" spaceLeft="medium">
                  <Feather name="plus-circle" size={20} />
                </AHText>
              </Box>

              <AHText
                type="detail"
                color="washedText"
                spaceTop="medium"
                textAlign="center"
              >
                Max filstorlek 10MB, max 20 filer i taget
              </AHText>
              <AHText type="detail" color="washedText" textAlign="center">
                Godkända filtyper: PDF, Word, Excel
              </AHText>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  opacity: 0.6,
                  right: 0,
                  transform: isDragingOver
                    ? "translateY(0) rotate(-12deg)"
                    : "translateY(100%)",
                  transition: "0.4s ease",
                }}
              >
                <Image
                  source={require("./images/fil-l.png")}
                  style={{ height: 100, width: 73, marginRight: 10 }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  opacity: 0.6,
                  left: 0,
                  transform: isDragingOver
                    ? "translateY(20%) rotate(12deg)"
                    : "translateY(100%)",
                  transition: "0.8s ease",
                }}
              >
                <Image
                  source={require("./images/fil-r.png")}
                  style={{ height: 70, width: 50, marginRight: 10 }}
                />
              </div>
            </Box>
          </div>
        )}

        {(files.length > 0 || filesWithErrors.length > 0) && (
          <Box
            style={{
              marginTop: 50,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            space="large"
          >
            <AHText type="title-small" color="washedText" spaceLeft="xlarge">
              Filnamn
            </AHText>
            <AHText type="title-small" color="washedText">
              Status
            </AHText>
          </Box>
        )}
        <Box>
          {files.map((file, i) => {
            return (
              <Box
                key={i + ""}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <TouchableBox
                  onPress={() => {
                    setFiles((oldFiles) => oldFiles.filter((_, j) => j !== i));
                  }}
                  style={{ marginBottom: 15 }}
                >
                  <AHText type="title" color="washedText">
                    <Feather name="x" size={20} />
                  </AHText>
                </TouchableBox>
                <Box
                  key={i + ""}
                  spaceRight="large"
                  borderColor="washedText"
                  cornerRadius="large"
                  style={{
                    flex: 1,
                    marginBottom: 15,
                    marginLeft: 20,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ flex: 1 }}>
                    <AHText>
                      {" "}
                      <input
                        style={{
                          padding: 5,
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "90%",
                          maxWidth: 300,
                          textIndent: 3,
                          borderWidth: 0,
                          borderBottom: "2px solid #C7C7C7",
                          background: "none",
                        }}
                        type={"text"}
                        value={file.name}
                        onChange={(e) => {
                          const v = e.target.value;
                          setFiles((oldFiles) =>
                            oldFiles.map((f, j) =>
                              j === i ? { ...f, name: v } : f
                            )
                          );
                        }}
                      />
                    </AHText>
                  </Box>
                  <Box style={{ flexDirection: "row", alignItems: "center" }}>
                    <AHText spaceLeft="medium" type="body" color="washedText">
                      {formatBytes(file.file.size)}
                    </AHText>
                    <AHText spaceLeft="medium" type="body" color="washedText">
                      Redo att laddas upp
                    </AHText>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box>
          {filesWithErrors.map(({ file, reason }, i) => (
            <Box
              key={i + ""}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              spaceRight="large"
              spaceTop="medium"
            >
              <Box style={{ flexDirection: "row" }}>
                <AHText type="title" color="negative">
                  <Feather name="alert-circle" size={20} />
                </AHText>
                <AHText type="title-small" spaceLeft="xlarge" color="negative">
                  {file.name}
                </AHText>
              </Box>
              <AHText color="negative">{reasonMessage(reason)}</AHText>
            </Box>
          ))}
        </Box>
      </Box>
      {(files.length > 0 || filesWithErrors.length > 0) && (
        <Box
          style={{
            backgroundColor: files.length <= 0 ? "#FFEBEB" : "#DEEBFC",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
          space="large"
        >
          <TouchableBox
            onPress={() => {
              setFiles([]);
              setFilesWithErrors([]);
            }}
          >
            <AHText type="subtitle">Avbryt</AHText>
          </TouchableBox>

          {files.length > 0 && (
            <AHButton
              loading={linkStatus.loading}
              style={{ maxHeight: 40, marginLeft: 40 }}
              title={`Ladda upp ${files?.length} filer`}
              themeType="primary"
              type="default"
              disabled={files.length <= 0}
              onPress={uploadFiles}
            >
              Ladda upp {files?.length} filer
            </AHButton>
          )}
        </Box>
      )}
    </>
  );
}

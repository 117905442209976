import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { Space, useLayoutContext } from "../LayoutProvider";
import { useCallback, useMemo } from "react";

import { BoxProps } from "./BoxProps";
import { useScaleFactor } from "../AHScaleFactor";
import { useTheme } from "./AHTheme";

function resolve<K extends string, V extends number>(
  record: Record<K, V>,
  scaleFactor: number,
  key?: K
) {
  const res = key ? record[key] : undefined;
  if (res !== undefined) {
    return Math.floor(res * scaleFactor);
  }
}

function resolveSpace(
  space: Record<Space, number>,
  keys: (Space | undefined)[],
  scaleFactor: number,
  inset?: number
) {
  const _space = keys.find((key) => key !== undefined);

  if (_space || inset !== undefined) {
    return Math.floor(
      ((resolve(space, 1, _space) ?? 0) + (inset ?? 0)) * scaleFactor
    );
  }
  return undefined;
}

export function useBoxStyle(props: BoxProps): StyleProp<ViewStyle> {
  const { space, radius } = useLayoutContext();
  const theme = useTheme();
  const sf = useScaleFactor();
  const scale = useCallback((inp: number) => Math.floor(inp * sf), [sf]);

  const shadow = useMemo(
    () =>
      props.shadow
        ? {
            ...theme.shadow[props.shadow],
            ...(props.shadowTint
              ? { shadowColor: theme[props.shadowTint] }
              : {}),
          }
        : {},
    [props.shadow, props.shadowTint, theme]
  );
  const style = useMemo(
    () =>
      StyleSheet.create({
        style: {
          width: props.width ? scale(props.width) : undefined,
          height: props.height ? scale(props.height) : undefined,
          paddingLeft: resolveSpace(
            space,
            [props.spaceLeft, props.spaceHorizontal, props.space],
            sf,
            props.insets?.left
          ),
          paddingRight: resolveSpace(
            space,
            [props.spaceRight, props.spaceHorizontal, props.space],
            sf,
            props.insets?.right
          ),
          paddingTop: resolveSpace(
            space,
            [props.spaceTop, props.spaceVertical, props.space],
            sf,
            props.insets?.top
          ),
          paddingBottom: resolveSpace(
            space,
            [props.spaceBottom, props.spaceVertical, props.space],
            sf,
            props.insets?.bottom
          ),
          borderTopRightRadius:
            resolve(radius, sf, props.cornerRadiusTopRight) ??
            resolve(radius, sf, props.cornerRadius),
          borderTopLeftRadius:
            resolve(radius, sf, props.cornerRadiusTopLeft) ??
            resolve(radius, sf, props.cornerRadius),
          borderBottomLeftRadius:
            resolve(radius, sf, props.cornerRadiusBottomLeft) ??
            resolve(radius, sf, props.cornerRadius),
          borderBottomRightRadius:
            resolve(radius, sf, props.cornerRadiusBottomRight) ??
            resolve(radius, sf, props.cornerRadius),

          backgroundColor: props.color ? theme[props.color] : undefined,
          borderColor: props.borderColor ? theme[props.borderColor] : undefined,

          ...shadow,
        },
      }).style,
    [
      props.borderColor,
      props.color,
      props.cornerRadius,
      props.cornerRadiusBottomLeft,
      props.cornerRadiusBottomRight,
      props.cornerRadiusTopLeft,
      props.cornerRadiusTopRight,
      props.height,
      props.insets?.bottom,
      props.insets?.left,
      props.insets?.right,
      props.insets?.top,
      props.space,
      props.spaceBottom,
      props.spaceHorizontal,
      props.spaceLeft,
      props.spaceRight,
      props.spaceTop,
      props.spaceVertical,
      props.width,
      radius,
      sf,
      shadow,
      space,
      theme,
      scale,
    ]
  );

  return style;
}

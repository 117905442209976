import { Portal } from "@gorhom/portal";
import { Box } from "arkh";
import React, { useEffect } from "react";

export function Modal(props: React.PropsWithChildren<any>) {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <Portal>
      <div
        onClick={() => {}}
        style={{
          position: "fixed",
          zIndex: 9,
          minHeight: "100vh",
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          transition: "transform 0.5s ease-in-out",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backdropFilter: "blur(3px)",
        }}
      >
        <Box
          cornerRadius="medium"
          color="surface"
          style={{ minHeight: 400, width: 600 }}
        >
          {props.children}
        </Box>
      </div>
    </Portal>
  );
}

import { TextInput, TextInputProps } from "react-native";
import { TextTypes, resolveAHTextStyle } from "../Text/AHText";

import { BoxProps } from "../AHTheme/BoxProps";
import React from "react";
import { useBoxStyle } from "../AHTheme/useBoxStyle";
import { useLayoutContext } from "../LayoutProvider";
import { useScaleFactor } from "../AHScaleFactor";
import { useTheme } from "../AHTheme/AHTheme";

export { InputDescription as AHInputDescription } from "./InputDescription";

export type AHInputProps = {
  textType?: TextTypes;
} & TextInputProps &
  BoxProps;

export const AHInput = React.forwardRef<TextInput, AHInputProps>(
  ({ textType = "body-big", ...props }, ref) => {
    const sf = useScaleFactor();
    const boxableStyle = useBoxStyle(props);
    const theme = useTheme();
    const layout = useLayoutContext();
    return (
      <TextInput
        ref={ref}
        underlineColorAndroid="transparent"
        autoCorrect={false}
        placeholderTextColor={theme.washedText}
        autoCapitalize="none"
        returnKeyType={props.multiline ? "default" : "done"}
        {...props}
        style={[
          boxableStyle,
          resolveAHTextStyle(textType, sf, theme, layout),
          props.style,
          {
            fontWeight: "normal", // fixes android custom fonts :S
          },
        ]}
      />
    );
  }
);

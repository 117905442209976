import { FieldPolicy } from "@apollo/client/cache";
import { relayStylePagination } from "@apollo/client/utilities";

export function todoPagination(): FieldPolicy<any> {
  const rs = relayStylePagination();

  return {
    ...rs,
    read: (items, opts) => {
      if (!rs.read) {
        return undefined;
      }
      const res = rs.read(items, opts);
      if (!res) {
        return res;
      }
      return {
        ...res,
        edges: res?.edges?.slice().sort((a, b) => {
          if (!a.cursor || !b.cursor) {
            return 0;
          }

          const aa =
            opts.readField<string>("ordinal", opts.readField<any>("node", a)) ??
            opts.readField<string>("cursor", a) ??
            "";
          const bb =
            opts.readField<string>("ordinal", opts.readField<any>("node", a)) ??
            opts.readField<string>("cursor", b) ??
            "";
          return bb.localeCompare(aa);
        }),
      };
    },
  };
}

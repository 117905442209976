import React, { useMemo } from "react";
import { AHText, AHTextFonts, Box, TouchableBox, useTime } from "arkh";
import { FileStatus, FileType } from "../globalTypes";
import { FileIcon } from "./FileIcon";
import { DateTime } from "luxon";

function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, "g"), replace);
}

export function FileListItem(props: {
  open: boolean;
  textHighlights: { text: string; isHighlighted: boolean }[];
  nameHighlights: { text: string; isHighlighted: boolean }[];
  onPress: () => void;
  file: {
    createdAt: string;
    url: string;
    type: FileType | null;
    name: string | null;
    status: FileStatus;
  };
}) {
  const now = useTime(60000);
  const date = DateTime.fromISO(props.file.createdAt);
  const isNew = useMemo(() => date.plus({ minute: 2 }) > now, [date, now]);
  return (
    <TouchableBox onPress={props.onPress}>
      <Box
        style={{
          flex: 1,
          marginBottom: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: isNew ? "rgb(234, 242, 252)" : "#FFF",
          borderWidth: 2,
        }}
        space="medium"
        color="surface"
        borderColor={props.open ? "primary" : "transparent"}
        cornerRadius="medium"
      >
        <Box style={{ alignItems: "center", flexDirection: "row" }}>
          <Box spaceRight="small">
            <FileIcon filetype={props.file.type} />
          </Box>
          <Box>
            <AHText type="subtitle" color="darkText">
              {props.nameHighlights.length === 0
                ? props.file.name
                : props.nameHighlights.map((v, i) => (
                    <AHText
                      type="subtitle"
                      font={v.isHighlighted ? AHTextFonts.BOLDER : undefined}
                      color={v.isHighlighted ? "darkerText" : "darkText"}
                      key={i}
                    >
                      {replaceAll(v.text, "\n", " ")}
                    </AHText>
                  ))}
            </AHText>
            {props.textHighlights.length > 0 ? (
              <AHText
                style={{ marginTop: 10 }}
                type="detail"
                color="darkText"
                cornerRadius="small"
              >
                {`"`}
                {props.textHighlights.map((v, i, arr) => {
                  const t =
                    i === 0
                      ? v.text.trimStart()
                      : i === arr.length - 1
                      ? v.text.trimEnd()
                      : v.text;
                  return (
                    <AHText
                      font={
                        v.isHighlighted ? AHTextFonts.BOLD : AHTextFonts.MEDIUM
                      }
                      color={v.isHighlighted ? "darkerText" : "darkText"}
                      key={i}
                    >
                      {replaceAll(t, "\n", " ")}
                    </AHText>
                  );
                })}
                {`"`}
              </AHText>
            ) : null}
          </Box>
        </Box>
        <Box style={{ flexDirection: "row" }}>
          <AHText>{date.toLocaleString(DateTime.DATETIME_SHORT)}</AHText>
        </Box>
      </Box>
    </TouchableBox>
  );
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FileStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  READY = "READY",
  WAIT_FOR_PROCESSING = "WAIT_FOR_PROCESSING",
}

export enum FileType {
  DOC = "DOC",
  DOCX = "DOCX",
  PDF = "PDF",
  XLS = "XLS",
  XLSX = "XLSX",
}

export enum IntegrationEmaginFlyerStatus {
  failed = "failed",
  pending = "pending",
  success = "success",
}

export enum NotificationSchedulingBase {
  COMMIT = "COMMIT",
  EXPIRATION = "EXPIRATION",
  START = "START",
}

export enum NumberTaskUnit {
  CURRENCY = "CURRENCY",
  CUSTOM = "CUSTOM",
}

export enum RecurrenceFrequency {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export enum Roles {
  ADMIN = "ADMIN",
  CO_WORKER = "CO_WORKER",
  MANAGER = "MANAGER",
}

export enum RoutineIcon {
  key = "key",
  residual = "residual",
  shelf_hole = "shelf_hole",
  waste = "waste",
}

export enum RoutineNotificationStrategy {
  DISABLED = "DISABLED",
  STANDARD = "STANDARD",
}

export enum RoutineOccasionStatus {
  COMPLETED = "COMPLETED",
  FUTURE = "FUTURE",
  IN_PROGRESS = "IN_PROGRESS",
  IN_PROGRESS_EXPIRED = "IN_PROGRESS_EXPIRED",
  NOT_COMPLETED = "NOT_COMPLETED",
  REQUIRED_COMPLETED = "REQUIRED_COMPLETED",
}

export enum RoutineType {
  DEFAULT = "DEFAULT",
  KPI = "KPI",
}

export enum StoreTier {
  basic = "basic",
  free = "free",
  light = "light",
  premium = "premium",
  standard = "standard",
  unlimited = "unlimited",
}

export enum StorecastTemplateType {
  Empty = "Empty",
  Information = "Information",
  LEAN = "LEAN",
  Operational = "Operational",
}

export enum TaskImportance {
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export enum TaskSentiment {
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
  POSITIVE = "POSITIVE",
}

export enum TeamIcon {
  account_hard_hat = "account_hard_hat",
  account_tie = "account_tie",
  apple = "apple",
  baby_carriage = "baby_carriage",
  baguette = "baguette",
  basket = "basket",
  bed = "bed",
  bread_slice = "bread_slice",
  broom = "broom",
  carrot = "carrot",
  cart = "cart",
  cash = "cash",
  cash_register = "cash_register",
  cheese = "cheese",
  coffe = "coffe",
  cookie = "cookie",
  cupcake = "cupcake",
  default = "default",
  desk_lamp = "desk_lamp",
  dolly = "dolly",
  fish = "fish",
  flower = "flower",
  food = "food",
  fork_knife = "fork_knife",
  forklift = "forklift",
  fridge = "fridge",
  hamburger = "hamburger",
  hammer_wrench = "hammer_wrench",
  laptop = "laptop",
  market = "market",
  meat = "meat",
  milk = "milk",
  pineapple = "pineapple",
  soda = "soda",
  sofa = "sofa",
  steak = "steak",
  tshirt = "tshirt",
  tv = "tv",
  watermelon = "watermelon",
}

export enum TodoCategoryIcon {
  Check = "Check",
  Message = "Message",
  Order = "Order",
  Sun = "Sun",
}

export enum TodoStatus {
  DONE = "DONE",
  PENDING = "PENDING",
}

export enum UserFlag {
  FinishedSetup = "FinishedSetup",
  NoteIntroduction = "NoteIntroduction",
  RoutineIntroduction = "RoutineIntroduction",
  TodoIntroduction = "TodoIntroduction",
}

export enum WidgetOverviewSize {
  TWO_X_FOUR = "TWO_X_FOUR",
  TWO_X_ONE = "TWO_X_ONE",
  TWO_X_THREE = "TWO_X_THREE",
  TWO_X_TWO = "TWO_X_TWO",
}

export interface AddConnection {
  check?: CreateTaskSectionConnetion | null;
  number?: CreateTaskNumberExistingSectionConnetion | null;
  text?: CreateTaskSectionConnetion | null;
}

export interface ConnectSiteInputUpdateInput {
  setCustomTitle?: string | null;
  setDescription?: string | null;
  setSuccessMessage?: string | null;
  setTagIds?: string[] | null;
  setTeamIds?: string[] | null;
}

export interface CreateConnectionInputInput {
  customTitle?: string | null;
  description?: string | null;
  successMessage?: string | null;
  tagIds?: string[] | null;
  teamIds: string[];
  todoCategoryId: string;
}

export interface CreateRoutineInput {
  connections?: AddConnection[] | null;
  icon?: RoutineIcon | null;
  notificationStrategy?: RoutineNotificationStrategy | null;
  schedule?: ScheduleInput | null;
  sections?: UpdateRoutineCreateSectionInput[] | null;
  startAt?: string | null;
  tasks?: CreateTaskInput[] | null;
  teamId: string;
  title: string;
  type?: RoutineType | null;
}

export interface CreateTaskCheckInput {
  id: string;
  imageIds?: string[] | null;
  instructions?: string | null;
  sentiments?: TaskSentiment[] | null;
  title: string;
}

export interface CreateTaskInput {
  check?: CreateTaskCheckInput | null;
  number?: CreateTaskNumberInput | null;
  text?: CreateTaskTextInput | null;
}

export interface CreateTaskNumberExistingSectionConnetion {
  greaterThan?: number | null;
  lessThan?: number | null;
  sectionId: string;
  taskId: string;
}

export interface CreateTaskNumberInput {
  id: string;
  imageIds?: string[] | null;
  instructions?: string | null;
  suffix?: string | null;
  title: string;
  unit?: NumberTaskUnit | null;
}

export interface CreateTaskSectionConnetion {
  sectionId: string;
  taskId: string;
}

export interface CreateTaskTextInput {
  id: string;
  imageIds?: string[] | null;
  instructions?: string | null;
  title: string;
}

export interface CreateWidgetTaskCollectionInputTask {
  sectionId: string;
  taskId: string;
}

export interface RecurrenceRuleInput {
  byhour?: number[] | null;
  byminute?: number[] | null;
  bymonth?: number[] | null;
  bymonthday?: number[] | null;
  bysecond?: number[] | null;
  byweekday?: number[] | null;
  byweekno?: number[] | null;
  byyearday?: number[] | null;
  dtstart?: string | null;
  duration?: number | null;
  freq: RecurrenceFrequency;
  interval?: number | null;
  tasks: RecurrenceRuleTaskDescriptionInput[];
  until?: string | null;
  wkst?: number | null;
}

export interface RecurrenceRuleTaskDescriptionInput {
  importance: TaskImportance;
  taskId: string;
}

export interface RemoveSection {
  sectionId: string;
}

export interface RemoveTaskConnection {
  sectionId: string;
  taskId: string;
}

export interface ScheduleInput {
  rules: RecurrenceRuleInput[];
  tzid?: string | null;
}

export interface StorecastFocusFileInput {
  fileId: string;
  pageId: string;
  scale?: number | null;
  transformX?: number | null;
  transformY?: number | null;
}

export interface TagCreateInput {
  color: string;
  title: string;
}

export interface TagUpdateColorInput {
  color: string;
  id: string;
}

export interface TagUpdateTitleInput {
  id: string;
  title: string;
}

export interface UpdateRoutineCreateSectionInput {
  id: string;
  teamId?: string | null;
  title?: string | null;
}

export interface UpdateRoutineInput {
  createConnections?: AddConnection[] | null;
  createSections?: UpdateRoutineCreateSectionInput[] | null;
  createTasks?: CreateTaskInput[] | null;
  deleteSections?: RemoveSection[] | null;
  removeConnections?: RemoveTaskConnection[] | null;
  setNotificationStrategy?: RoutineNotificationStrategy | null;
  setSectionOrder?: string[] | null;
  setTeamId?: string | null;
  setTitle?: string | null;
  updateSections?: UpdateSectionInput[] | null;
  updateTasks?: UpdateRoutineUpdateTaskInput[] | null;
}

export interface UpdateRoutineUpdateTaskInput {
  check?: UpdateTaskCheckInput | null;
  number?: UpdateTaskNumberInput | null;
  taskId: string;
  text?: UpdateTaskTextInput | null;
}

export interface UpdateSectionInput {
  sectionId: string;
  setTaskOrder?: string[] | null;
  setTeamId?: string | null;
  setTitle?: string | null;
  unsetTeamId?: boolean | null;
}

export interface UpdateTaskCheckInput {
  setImageIds?: string[] | null;
  setInstructions?: string | null;
  setSentiments?: TaskSentiment[] | null;
  setTitle?: string | null;
}

export interface UpdateTaskNumberInput {
  setImageIds?: string[] | null;
  setInstructions?: string | null;
  setTitle?: string | null;
  setUnit?: NumberTaskUnit | null;
}

export interface UpdateTaskTextInput {
  setImageIds?: string[] | null;
  setInstructions?: string | null;
  setTitle?: string | null;
}

export interface WidgetRoutinePositivePercentageUpdateInput {
  setRoutineIds?: string[] | null;
  setTitle?: string | null;
}

export interface WidgetTaskCollectionUpdateInput {
  setTasks?: CreateWidgetTaskCollectionInputTask[] | null;
  setTitle?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

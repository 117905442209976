import { AHThemeType, useTheme } from "./AHTheme/AHTheme";
import React, { useMemo } from "react";
import { useFragment, graphql } from "react-relay";
import { AHStatusCircle } from "./AHStatusCircle";
import Color from "color";
import { DateTime } from "luxon";
import { RoutineOccasionStatus } from "../globalTypes";
import { CircleSize, useCircleSize } from "./LayoutProvider";
import { useTime } from "./useTime";
import {
  RoutineOccasionStatusCircle_RoutineOccasion$key,
  RoutineOccasionStatus as ROS,
} from "./__generated__/RoutineOccasionStatusCircle_RoutineOccasion.graphql";

const calculateProgressFromDates = (
  now: DateTime,
  to?: string | null,
  from?: string | null
) => {
  if (!to || !from) {
    return 0;
  }
  const totalSecondsPending = Math.abs(
    DateTime.fromISO(to).diff(DateTime.fromISO(from)).as("seconds")
  );

  const secondsSinceStarted = Math.abs(
    now.diff(DateTime.fromISO(from)).as("seconds")
  );

  return Math.min(secondsSinceStarted / totalSecondsPending, 1);
};

export const colorForStatus = (
  theme: AHThemeType,
  status?: RoutineOccasionStatus
): string => {
  switch (status) {
    case RoutineOccasionStatus.IN_PROGRESS:
      return theme.primary;
    case RoutineOccasionStatus.REQUIRED_COMPLETED:
    case RoutineOccasionStatus.COMPLETED:
      return theme.positive;
    case RoutineOccasionStatus.IN_PROGRESS_EXPIRED:
    case RoutineOccasionStatus.NOT_COMPLETED:
      return theme.negative;
    case RoutineOccasionStatus.FUTURE:
      return theme.neutral;
    case undefined:
      return theme.neutral;
  }
  //    throw new Error(status + "has no color");
};

export const fillForStatus = (status?: RoutineOccasionStatus) => {
  switch (status) {
    case RoutineOccasionStatus.COMPLETED:
    case RoutineOccasionStatus.NOT_COMPLETED:
      return true;
    case RoutineOccasionStatus.REQUIRED_COMPLETED:
    case RoutineOccasionStatus.IN_PROGRESS:
    case RoutineOccasionStatus.IN_PROGRESS_EXPIRED:
    case RoutineOccasionStatus.FUTURE:
    case undefined:
      return false;
  }
};

const iconForStatus = (status?: RoutineOccasionStatus) => {
  switch (status) {
    case RoutineOccasionStatus.IN_PROGRESS:
    case RoutineOccasionStatus.IN_PROGRESS_EXPIRED:
    case RoutineOccasionStatus.FUTURE:
    case RoutineOccasionStatus.REQUIRED_COMPLETED:
      return "arrow-right";
    case RoutineOccasionStatus.COMPLETED:
      return "check";
    case RoutineOccasionStatus.NOT_COMPLETED:
      return "x";
    case undefined:
      return "cloud-drizzle";
  }
};

export const RoutineOccasionStatusCircle = React.memo(
  (props: {
    status?: RoutineOccasionStatus;
    expireAt?: string | null;
    startAt?: string | null;
    size?: number;
    circleSize?: CircleSize;
  }) => {
    const theme = useTheme();
    const now = useTime(6000);
    const size = useCircleSize(props.circleSize ?? "medium");
    const status = props.status;
    const isFilled = useMemo(() => fillForStatus(status), [status]);

    const mainColor = colorForStatus(theme, status);
    const trackBackgroundColor = useMemo(() => {
      if (status === RoutineOccasionStatus.FUTURE) {
        return mainColor;
      }
      if (status === RoutineOccasionStatus.REQUIRED_COMPLETED) {
        return Color(mainColor).mix(Color(theme.surface), 0.5).toString();
      } else {
        return Color(theme.primary).mix(Color(theme.surface), 0.6).toString();
      }
    }, [mainColor, status, theme.primary, theme.surface]);

    const progress = useMemo(
      () =>
        status === RoutineOccasionStatus.FUTURE
          ? 0
          : calculateProgressFromDates(now, props.expireAt, props.startAt) ?? 0,
      [now, props.expireAt, props.startAt, status]
    );
    const icon = useMemo(() => iconForStatus(status), [status]);

    return (
      <AHStatusCircle
        filledColor={mainColor}
        shouldInsetFill={false}
        icon={icon}
        iconColor={isFilled ? theme.lightText : mainColor}
        progress={progress}
        trackIsFilled={
          progress === 1 || status === RoutineOccasionStatus.REQUIRED_COMPLETED
        }
        trackFillColor={mainColor}
        isFilled={isFilled}
        size={size}
        trackBackgroundColor={trackBackgroundColor}
      />
    );
  }
);

export const colorForStatusRelay = (
  theme: AHThemeType,
  status?: ROS
): string => {
  switch (status) {
    case "IN_PROGRESS":
      return theme.primary;
    case "REQUIRED_COMPLETED":
    case "COMPLETED":
      return theme.positive;
    case "IN_PROGRESS_EXPIRED":
    case "NOT_COMPLETED":
      return theme.negative;
    case "FUTURE":
      return theme.neutral;
    case undefined:
      return theme.neutral;
  }
  //    throw new Error(status + "has no color");
};

export const fillForStatusRelay = (status?: ROS) => {
  switch (status) {
    case "COMPLETED":
    case "NOT_COMPLETED":
      return true;
    case "REQUIRED_COMPLETED":
    case "IN_PROGRESS":
    case "IN_PROGRESS_EXPIRED":
    case "FUTURE":
    case undefined:
      return false;
  }
};

const iconForStatusRelay = (status?: ROS) => {
  switch (status) {
    case "IN_PROGRESS":
    case "IN_PROGRESS_EXPIRED":
    case "FUTURE":
    case "REQUIRED_COMPLETED":
      return "arrow-right";
    case "COMPLETED":
      return "check";
    case "NOT_COMPLETED":
      return "x";
    case undefined:
      return "cloud-drizzle";
  }
};

export const RoutineOccasionStatusCircleRelay = React.memo(
  (props: {
    occasion: RoutineOccasionStatusCircle_RoutineOccasion$key;
    size?: number;
    circleSize?: CircleSize;
  }) => {
    const { status, startAt, expireAt } = useFragment(
      graphql`
        fragment RoutineOccasionStatusCircle_RoutineOccasion on RoutineOccasion {
          status
          expireAt
          startAt
        }
      `,
      props.occasion
    );
    const theme = useTheme();
    const now = useTime(6000);
    const size = useCircleSize(props.circleSize ?? "medium");

    const isFilled = useMemo(() => fillForStatusRelay(status), [status]);

    const mainColor = colorForStatusRelay(theme, status);
    const trackBackgroundColor = useMemo(() => {
      if (status === RoutineOccasionStatus.FUTURE) {
        return mainColor;
      }
      if (status === RoutineOccasionStatus.REQUIRED_COMPLETED) {
        return Color(mainColor).mix(Color(theme.surface), 0.5).toString();
      } else {
        return Color(theme.primary).mix(Color(theme.surface), 0.6).toString();
      }
    }, [mainColor, status, theme.primary, theme.surface]);

    const progress = useMemo(
      () =>
        status === RoutineOccasionStatus.FUTURE
          ? 0
          : calculateProgressFromDates(now, expireAt, startAt) ?? 0,
      [now, expireAt, startAt, status]
    );
    const icon = useMemo(() => iconForStatusRelay(status), [status]);

    return (
      <AHStatusCircle
        filledColor={mainColor}
        shouldInsetFill={false}
        icon={icon}
        iconColor={isFilled ? theme.lightText : mainColor}
        progress={progress}
        trackIsFilled={
          progress === 1 || status === RoutineOccasionStatus.REQUIRED_COMPLETED
        }
        trackFillColor={mainColor}
        isFilled={isFilled}
        size={size}
        trackBackgroundColor={trackBackgroundColor}
      />
    );
  }
);

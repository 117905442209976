import { AHButton } from "./AHButton";
import { AHText } from "./Text/AHText";
import { Box } from "./AHTheme/Box";
import React from "react";

export const AHQueryHandlerError = ({
  title = "Ett fel uppstod",
  retryButtonTitle = "Försök igen",
  ...props
}: {
  isLoading: boolean;
  onPress: () => void;
  title?: string;
  retryButtonTitle?: string;
}) => {
  return (
    <Box
      spaceHorizontal="medium"
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AHText spaceBottom="medium" type="title-small">
        {title}
      </AHText>
      <AHText
        type="body"
        spaceBottom="medium"
        style={{ maxWidth: 450, textAlign: "center" }}
      >
        Det verkar vara ett problem med internetanslutningen. Kontrollera att
        din enhet är ansluten till internet.
      </AHText>
      <AHButton
        loading={props.isLoading}
        onPress={props.onPress}
        themeType="primary"
        title={retryButtonTitle}
      />
    </Box>
  );
};

import * as Sentry from "sentry-expo";

import AsyncStorage from "@react-native-async-storage/async-storage";

export const enum Features {
  FocusModeBottomSheet = "focusModeBottomSheet",
  FocusMode = "focusMode",
  StoresprintConnect = "storesprintConnet",
}
/*eslint-disable no-process-env */
/*eslint-disable no-undef */
const authDomainProd = "https://login.storesprint.app";
export const authProd = {
  domain: authDomainProd,
  oauth: {
    clientId: "rEHwqjRIvdshgcw8asDf81Grw6Mh54Jp",
    issuer: authDomainProd + "/",
    scopes: ["openid", "profile", "email", "offline_access"],
    serviceConfiguration: {
      authorizationEndpoint: `${authDomainProd}/authorize`,
      tokenEndpoint: `${authDomainProd}/oauth/token`,
      registrationEndpoint: `${authDomainProd}/oidc/register`,
      revocationEndpoint: `${authDomainProd}/oauth/revoke`,
    },
  },
};
//beta.storesprint.pages.dev
const authDomainBeta = "https://storesprint-beta.eu.auth0.com";
export const authBeta = {
  domain: authDomainBeta,
  oauth: {
    clientId: "Lf15pbNAICHbeWqKDhYQ6r6GdDNuYZgV",
    issuer: authDomainBeta + "/",
    scopes: ["openid", "profile", "email", "offline_access"],
    serviceConfiguration: {
      authorizationEndpoint: `${authDomainBeta}/authorize`,
      tokenEndpoint: `${authDomainBeta}/oauth/token`,
      registrationEndpoint: `${authDomainBeta}/oidc/register`,
      revocationEndpoint: `${authDomainBeta}/oauth/revoke`,
    },
  },
};

const baseConfig = {
  Sentry,
  Storage: AsyncStorage,
};

const prodConfig = {
  ...baseConfig,
  name: "Production",
  auth: authProd,
  baseWSUrl: "wss://api.storesprint.app",
  baseUrl: "https://api.storesprint.app",
  production: true,
  hasFeature: (feature: Features, storeId?: string, userId?: string) => true,
  adobePDFPreviewClientId: "0f93b69335e14038a075ddae3fa5b349",
};

const localConfig = {
  ...baseConfig,
  name: "Local Config",
  auth: authBeta,
  baseWSUrl: "ws://192.168.0.40:8080",
  baseUrl: "http://192.168.0.40:8080",
  production: false,
  hasFeature: (feature: Features, storeId?: string, userId?: string) => true,
  adobePDFPreviewClientId: "3395b2a0247a4416bc86d505687fb3c5",
};

const betaLocalConfig = {
  ...baseConfig,
  name: "Beta",
  auth: authBeta,
  baseWSUrl: "wss://beta.api.storesprint.app",
  baseUrl: "https://beta.api.storesprint.app",
  production: false,
  hasFeature: (feature: Features, storeId?: string, userId?: string) => true,
  adobePDFPreviewClientId: "3395b2a0247a4416bc86d505687fb3c5",
};

const betaConfig = {
  ...baseConfig,
  name: "Beta",
  auth: authBeta,
  baseWSUrl: "wss://beta.api.storesprint.app",
  baseUrl: "https://beta.api.storesprint.app",
  production: false,
  hasFeature: (feature: Features, storeId?: string, userId?: string) => true,
  adobePDFPreviewClientId: "03af0f96e7544819b2fe00ce7a9ecec0",
};

const getConfig = () => {
  if (__DEV__) {
    return betaLocalConfig;
  }
  switch (process.env.STORESPRINT_CONFIG) {
    case "beta":
      return betaConfig;
    case "prod":
      return prodConfig;
    case "local":
      return localConfig;
  }
  console.warn("NO CONFIG SET");
  return prodConfig;
};

export const config = getConfig();

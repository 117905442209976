import { AHText } from "./Text";
import { Box } from "./AHTheme/Box";
import { BoxProps } from ".";
import { Circle } from "./AHTheme/Circle";
import React from "react";
import { useTheme } from "./AHTheme/AHTheme";

type AHTagProps = {
  title: string;
  color: string;
  selected?: boolean;
  type?: "filled" | "outside";
  size?: "small" | "large";
} & Omit<BoxProps, "color">;

type TagProps = Omit<AHTagProps, "type">;

function FilledTag({
  title,
  color,
  selected = true,
  size = "large",
  ...rest
}: TagProps) {
  const theme = useTheme();
  return (
    <Box
      cornerRadius="small"
      spaceVertical={size === "small" ? "xxsmall" : "xsmall"}
      spaceHorizontal={size === "small" ? "xsmall" : "small"}
      {...rest}
      style={{
        borderColor: color,
        borderWidth: 1,
        flexShrink: 1,
        backgroundColor: selected ? color : "transparent",
      }}
    >
      <AHText
        type={size === "small" ? "detail" : "body"}
        ellipsizeMode="tail"
        numberOfLines={1}
        style={{
          flexShrink: 1,
          color: selected ? theme.lightText : color,
          fontFamily: "system-bold",
        }}
      >
        {title}
      </AHText>
    </Box>
  );
}

function OutsideTag({
  title,
  color,
  selected = true,
  size = "large",
  ...rest
}: TagProps) {
  return (
    <Box
      cornerRadius="large"
      style={{ flexDirection: "row", alignItems: "center", flexShrink: 1 }}
      spaceRight="xxsmall"
      {...rest}
    >
      <Circle
        size="badge"
        style={{ backgroundColor: color, flexShrink: 0, flexGrow: 0 }}
      />
      <AHText
        spaceLeft="xxsmall"
        type={size === "small" ? "detail" : "body"}
        style={{ flexShrink: 1 }}
        numberOfLines={1}
      >
        {title}
      </AHText>
    </Box>
  );
}

const OutsideTagM = React.memo(OutsideTag);
const FilledTagM = React.memo(FilledTag);

function AHTagC(props: AHTagProps) {
  const { type, ...tagProps } = props;
  if (type === "outside") {
    return <OutsideTagM {...tagProps} />;
  }
  return <FilledTagM {...tagProps} />;
}
export const AHTag = React.memo(AHTagC);

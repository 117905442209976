import { FieldPolicy, Reference } from "@apollo/client/cache";

// Whether TEdge<TNode> is a normalized Reference or a non-normalized
// object, it needs a .cursor property where the relayStylePagination
// merge function can store cursor strings taken from pageInfo. Storing an
// extra reference.cursor property should be safe, and is easier than
// attempting to update the cursor field of the normalized StoreObject
// that the reference refers to, or managing edge wrapper objects
// (something I attempted in #7023, but abandoned because of #7088).
type TEdge<TNode> =
  | {
      cursor?: string;
      node: TNode;
    }
  | (Reference & { cursor?: string });

type TPageInfo = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string;
  endCursor: string;
};

type TExistingRelay<TNode> = Readonly<{
  edges: TEdge<TNode>[];
  pageInfo: TPageInfo;
}>;

type TIncomingRelay<TNode> = {
  edges?: TEdge<TNode>[];
  pageInfo?: TPageInfo;
};

type RelayFieldPolicy<TNode> = FieldPolicy<
  TExistingRelay<TNode>,
  TIncomingRelay<TNode>,
  TIncomingRelay<TNode>
>;

type KeyArgs = FieldPolicy<any>["keyArgs"];

// Returns any unrecognized properties of the given object.
const getExtras = (obj: Record<string, any>) => {
  const { edges, pageInfo, ...rest } = obj;
  return rest;
};

function makeEmptyData(): TExistingRelay<any> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: "",
      endCursor: "",
    },
  };
}

export function occasionPagination<TNode = Reference>(
  keyArgs: KeyArgs = false
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,

    read(existing, { canRead, readField }) {
      if (!existing) return;

      const edges: TEdge<TNode>[] = [];
      let startCursor = "";
      let endCursor = "";
      existing.edges.forEach((edge) => {
        // Edges themselves could be Reference objects, so it's important
        // to use readField to access the edge.edge.node property.
        if (canRead(readField("node", edge as any))) {
          edges.push(edge);
          if (edge.cursor) {
            startCursor = startCursor || edge.cursor;
            endCursor = edge.cursor;
          }
        }
      });

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          startCursor,
          endCursor,
        },
      };
    },

    merge(
      existing = makeEmptyData(),
      incoming,
      { args, isReference, readField }
    ) {
      const incomingEdges = incoming.edges
        ? incoming.edges.map((edge) => {
            if (isReference((edge = { ...edge }))) {
              // In case edge is a Reference, we read out its cursor field and
              // store it as an extra property of the Reference object.
              edge.cursor = readField<string>("cursor", edge);
            }
            return edge;
          })
        : [];

      if (incoming.pageInfo) {
        // In case we did not request the cursor field for edges in this
        // query, we can still infer some of those cursors from pageInfo.
        const { startCursor, endCursor } = incoming.pageInfo;
        const firstEdge = incomingEdges[0];
        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor;
        }
        const lastEdge = incomingEdges[incomingEdges.length - 1];
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor;
        }
      }

      let prefix = existing.edges;
      let suffix: typeof prefix = [];

      if (args?.after && args.after !== "NOW") {
        // This comparison does not need to use readField("cursor", edge),
        // because we stored the cursor field of any Reference edges as an
        // extra property of the Reference object.
        const index = prefix.findIndex((edge) => edge.cursor === args.after);

        if (index >= 0) {
          prefix = prefix.slice(0, index + 1);
          // suffix = []; // already true
        }
      } else if (args?.before && args.before !== "NOW") {
        const index = prefix.findIndex((edge) => edge.cursor === args.before);

        suffix = index < 0 ? prefix : prefix.slice(index);
        prefix = [];
      } else if (incoming.edges) {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = [];
      }

      const edges = [...prefix, ...incomingEdges, ...suffix];

      const firstEdge = edges[0];
      const lastEdge = edges[edges.length - 1];

      const pageInfo: TPageInfo = {
        ...incoming.pageInfo,
        ...existing.pageInfo,
        startCursor: firstEdge?.cursor ?? "",
        endCursor: lastEdge?.cursor ?? "",
      };

      if (incoming.pageInfo) {
        const { hasPreviousPage, hasNextPage } = incoming.pageInfo;
        // Keep existing.pageInfo.has{Previous,Next}Page unless the
        // placement of the incoming edges means incoming.hasPreviousPage
        // or incoming.hasNextPage should become the new values for those
        // properties in existing.pageInfo.
        if (!prefix.length && hasPreviousPage !== undefined) {
          pageInfo.hasPreviousPage = hasPreviousPage;
        }
        if (!suffix.length && hasNextPage !== undefined) {
          pageInfo.hasNextPage = hasNextPage;
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo,
      };
    },
  };
}

import React, { useRef } from "react";
import { AHIcon, AHText, Box, TouchableBox } from "arkh";
import { Feather } from "@expo/vector-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { gql, useQuery } from "@apollo/client";
import { NavbarQuery } from "./__generated__/NavbarQuery";
type IconTypes = "user" | "file-text" | "refresh-cw";
const NavLink = (props: {
  name: string;
  logo: IconTypes;
  selected: boolean;
}) => {
  return (
    <div style={{ cursor: "pointer" }}>
      <Box>
        <AHText
          type="title"
          color="background"
          spaceVertical="medium"
          style={{ opacity: props.selected ? 1 : 0.5 }}
        >
          <Feather name={props.logo} size={24} style={{ marginRight: 15 }} />
          {props.name}
        </AHText>
      </Box>
    </div>
  );
};
export function Navbar(props: {
  storeId?: string;
  setStoreId: (storeId: string) => void;
}) {
  const u = useAuth0();

  const { data } = useQuery<NavbarQuery>(gql`
    query NavbarQuery {
      store {
        id
        name
      }
      me {
        id
        stores {
          id
          name
        }
      }
    }
  `);

  const selectRef = useRef<HTMLSelectElement>(null);

  return (
    <Box
      color="primary"
      style={{
        minWidth: 210,
        paddingTop: 100,
      }}
      space="medium"
    >
      <Box space="medium" spaceTop="none">
        <Box style={{ flexDirection: "row" }}>
          <AHText type="title" color="lightText">
            <select
              name="store-input"
              id="store-input"
              ref={selectRef}
              value={data?.store.id}
              onChange={(e) => {
                props.setStoreId(e.target.value);
              }}
              style={{ cursor: "pointer" }}
            >
              {data?.me.stores.map((s) => (
                <option value={s.id} key={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </AHText>

          <Box style={{ flexDirection: "row", alignItems: "center" }}>
            <AHIcon type="chevron-right" color="lightText" />
          </Box>
        </Box>
        <AHText color="lightText">{u.user?.name}</AHText>
        <TouchableBox
          onPress={() => {
            u.logout({ returnTo: window.location.origin });
          }}
        >
          <AHText
            style={{ justifyContent: "space-around", alignContent: "center" }}
            type="subtitle"
            spaceVertical="medium"
            color="lightText"
          >
            Logga ut{" "}
            <Box spaceLeft="xsmall">
              <Feather name="log-out" size={12} />
            </Box>
          </AHText>
        </TouchableBox>
      </Box>

      <Box space="medium">
        <AHText
          type="title"
          color="background"
          style={{ opacity: 0.5 }}
          spaceVertical="medium"
        >
          ADMIN
        </AHText>
        <NavLink name="Filer" logo="file-text" selected={true} />
        {
          // <NavLink name="Rutiner" logo="refresh-cw" selected={false} />  <NavLink name="Användare" logo="user" selected={false} />
        }
      </Box>
    </Box>
  );
}

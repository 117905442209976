import {
  AntDesign,
  EvilIcons,
  Feather,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { ColorType, useTheme } from "./AHTheme/AHTheme";
import { IconSize, useLayoutContext } from "./LayoutProvider";

import { BoxProps } from "./AHTheme/BoxProps";
import React, { useMemo } from "react";
import { useBoxStyle } from "./AHTheme/useBoxStyle";
import { useScaleFactor } from "./AHScaleFactor";

export type AHIconType =
  | "PLU"
  | "team"
  | "created-at"
  | "deadline"
  | "minus"
  | "todo-category"
  | "dashboard"
  | "text-response"
  | "routine"
  | "task-list"
  | "notification"
  | "notification-off"
  | "storecast"
  | "user"
  | "users"
  | "issue"
  | "check"
  | "message"
  | "truck"
  | "sun"
  | "camera"
  | "more"
  | "more-outlined"
  | "add"
  | "add-opaque"
  | "edit"
  | "chevron-left"
  | "chevron-right"
  | "chevron-down"
  | "chevron-up"
  | "instructions"
  | "image"
  | "winner"
  | "schedule"
  | "drag"
  | "remove"
  | "remove-outline"
  | "trash"
  | "focus-mode"
  | "focus-mode-active"
  | "filter"
  | "show-in-feed"
  | "connection-error"
  | "clear"
  | "pencil"
  | "favorite"
  | "settings"
  | "assigned-user"
  | "help"
  | "integration"
  | "connect"
  | "files"
  | "KPI"
  | "team-home"
  | "analytics"
  | "video"
  | "blogpost"
  | "search";

function asertNever(_: never) {
  console.warn("No implementation for ahicon");
}
export type AHIconProps = {
  type: AHIconType;
  size?: IconSize;
  color?: ColorType;
  customColor?: string;
} & Omit<BoxProps, "color">;

function AHIconC(props: AHIconProps) {
  const { color: c, size: s, customColor, ...boxProps } = props;
  const iconStyle = useBoxStyle(boxProps);
  const theme = useTheme();
  const layout = useLayoutContext();
  const color = customColor ?? theme[c ?? "darkerText"];
  const sf = useScaleFactor();
  const size = layout.icon[s ?? "small"] * sf;

  switch (props.type) {
    case "PLU":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="shopping-cart"
        />
      );
    case "KPI":
      return (
        <Ionicons style={iconStyle} color={color} size={size} name="key" />
      );
    case "settings":
      return (
        <Ionicons
          style={iconStyle}
          color={color}
          size={size}
          name="cog-outline"
        />
      );
    case "favorite":
      return (
        <Feather style={iconStyle} color={color} size={size} name="star" />
      );
    case "drag":
      return (
        <Feather style={iconStyle} color={color} size={size} name="menu" />
      );
    case "created-at":
      return (
        <MaterialCommunityIcons
          style={iconStyle}
          color={color}
          size={size}
          name="calendar"
        />
      );
    case "show-in-feed":
      return (
        <AntDesign name="pushpin" style={iconStyle} color={color} size={size} />
      );
    case "trash":
      return (
        <Ionicons
          style={iconStyle}
          color={color}
          size={size}
          name="ios-trash-outline"
        />
      );
    case "schedule":
      return (
        <Feather style={iconStyle} color={color} size={size} name="calendar" />
      );
    case "winner":
      return (
        <FontAwesome5
          style={iconStyle}
          color={color}
          size={size}
          name="crown"
        />
      );
    case "image":
      return (
        <Feather style={iconStyle} color={color} size={size} name="image" />
      );
    case "text-response":
      return (
        <Feather style={iconStyle} color={color} size={size} name="book-open" />
      );
    case "instructions":
      return (
        <Feather style={iconStyle} color={color} size={size} name="book-open" />
      );
    case "deadline":
      return (
        <MaterialCommunityIcons
          style={iconStyle}
          color={color}
          size={size}
          name="calendar-clock"
        />
      );
    case "chevron-left":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="chevron-left"
        />
      );
    case "chevron-right":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="chevron-right"
        />
      );
    case "chevron-up":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="chevron-up"
        />
      );
    case "chevron-down":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="chevron-down"
        />
      );
    case "edit":
      return (
        <Feather style={iconStyle} color={color} size={size} name="edit" />
      );
    case "remove":
      return (
        <Feather style={iconStyle} color={color} size={size} name="minus" />
      );
    case "remove-outline":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="minus-circle"
        />
      );
    case "add":
      return (
        <Feather style={iconStyle} color={color} size={size} name="plus" />
      );
    case "add-opaque":
      return (
        <FontAwesome5
          style={iconStyle}
          color={color}
          size={size}
          name="plus-circle"
        />
      );
    case "more":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="more-horizontal"
        />
      );
    case "more-outlined":
      return (
        <Ionicons
          style={iconStyle}
          color={color}
          size={size + 3}
          name="ios-ellipsis-horizontal-circle"
        />
      );
    case "check":
      return (
        <Feather style={iconStyle} color={color} size={size} name="check" />
      );
    case "message":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="message-circle"
        />
      );
    case "truck":
      return (
        <Feather style={iconStyle} color={color} size={size} name="truck" />
      );
    case "sun":
      return <Feather style={iconStyle} color={color} size={size} name="sun" />;
    case "issue":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="alert-triangle"
        />
      );
    case "todo-category":
      return (
        <Feather style={iconStyle} color={color} size={size} name="inbox" />
      );
    case "task-list":
      return (
        <Feather style={iconStyle} color={color} size={size} name="clipboard" />
      );
    case "storecast":
      return <Feather style={iconStyle} color={color} size={size} name="tv" />;
    case "user":
      return (
        <Feather style={iconStyle} color={color} size={size} name="user" />
      );
    case "assigned-user":
      return (
        <Feather
          style={iconStyle}
          color={color}
          size={size}
          name="user-check"
        />
      );
    case "users":
      return (
        <MaterialCommunityIcons
          style={iconStyle}
          color={color}
          size={size}
          name="account-multiple"
        />
      );
    case "team":
      return (
        <MaterialCommunityIcons
          style={iconStyle}
          color={color}
          size={size}
          name="account-group"
        />
      );
    case "dashboard":
      return (
        <Feather style={iconStyle} color={color} size={size} name="layout" />
      );

    case "routine":
      return (
        <MaterialIcons
          style={iconStyle}
          color={color}
          size={size}
          name="loop"
        />
      );
    case "minus":
      return (
        <Feather style={iconStyle} color={color} size={size} name="minus" />
      );
    case "notification":
      return (
        <Feather style={iconStyle} color={color} size={size} name="bell" />
      );
    case "notification-off":
      return (
        <Feather style={iconStyle} color={color} size={size} name="bell-off" />
      );
    case "camera":
      return (
        <Feather style={iconStyle} color={color} size={size} name="camera" />
      );
    case "focus-mode":
      return (
        <MaterialIcons
          style={iconStyle}
          color={color}
          size={size}
          name="center-focus-weak"
        />
      );
    case "focus-mode-active":
      return (
        <MaterialIcons
          style={iconStyle}
          color={customColor ?? theme[c ?? "primary"]}
          size={size}
          name="center-focus-strong"
        />
      );
    case "filter":
      return (
        <Feather
          style={iconStyle}
          color={customColor ?? theme[c ?? "primary"]}
          size={size}
          name="filter"
        />
      );
    case "connection-error":
      return (
        <MaterialCommunityIcons
          style={iconStyle}
          color={customColor ?? theme[c ?? "primary"]}
          size={size}
          name="signal-off"
        />
      );
    case "clear":
      return (
        <MaterialIcons
          style={iconStyle}
          color={color}
          size={size}
          name="clear"
        />
      );
    case "pencil":
      return (
        <Ionicons
          name="ios-pencil"
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    case "help":
      return (
        <Feather
          name="help-circle"
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    case "integration":
      return (
        <Feather name="link" style={iconStyle} color={color} size={size} />
      );

    case "team-home":
      return (
        <Feather name="home" style={iconStyle} color={color} size={size} />
      );

    case "connect":
      return (
        <MaterialCommunityIcons
          name="human-greeting"
          style={iconStyle}
          color={color}
          size={size}
        />
      );

    case "files":
      return (
        <Feather name="file" style={iconStyle} color={color} size={size} />
      );

    case "analytics":
      return (
        <Feather
          name="bar-chart-2"
          style={iconStyle}
          color={color}
          size={size}
        />
      );

    case "blogpost":
      return (
        <Feather name="file-text" style={iconStyle} color={color} size={size} />
      );
    case "video":
      return (
        <Feather name="play" style={iconStyle} color={color} size={size} />
      );
    case "search":
      return (
        <Feather name="search" style={iconStyle} color={color} size={size} />
      );

    default:
      asertNever(props.type);
      return null;
  }
}

export const AHIcon = React.memo(AHIconC);

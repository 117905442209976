import {
  Circle,
  Defs,
  G,
  LinearGradient,
  Mask,
  Rect,
  Stop,
  Svg,
} from "react-native-svg";
import {
  PixelRatio,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import React, { ComponentProps, useMemo, useRef } from "react";

import { Feather } from "@expo/vector-icons";
import { useTheme } from "../../cross-platform/AHTheme/AHTheme";

const scaleFactor = PixelRatio.get();

function StatusCircleNonGradient(props: {
  filled: boolean;
  color: string;
  diameter: number;
  strokeWidth: number;
  innerDiameter: number;
  fillColor?: string;
}) {
  return (
    <View
      style={{
        opacity: 1,
        left: 1,
        top: 1,
        position: "absolute",
        width: props.diameter,
        height: props.diameter,
        borderRadius: props.diameter,
        borderColor: props.color,
        borderWidth: props.strokeWidth,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",

        flex: 1,
      }}
    >
      {props.fillColor ? (
        <View
          style={{
            width: props.innerDiameter,
            height: props.innerDiameter,
            borderRadius: props.innerDiameter,
            borderColor: props.color,
            borderWidth: props.strokeWidth,
            backgroundColor: props.color,
          }}
        />
      ) : null}
    </View>
  );
}
export const AHStatusCircle = React.memo(
  (props: {
    isFilled: boolean;
    filledColor: string;
    icon?: ComponentProps<typeof Feather>["name"];
    iconColor?: string;
    progress: number;
    trackIsFilled: boolean;
    trackFillColor: string;
    trackBackgroundColor: string;
    shouldInsetFill?: boolean;
    size: number;
    enableNonGradientOptimization?: boolean;
  }) => {
    const theme = useTheme();

    const id = useRef(Math.random()).current;

    const sw = (props.size / 40) * 3;
    const strokeWidth = useMemo(
      () => PixelRatio.roundToNearestPixel(sw * scaleFactor),
      [sw]
    );

    const size = props.size * scaleFactor;

    const radius = size / 2 - strokeWidth / 2 - 2;
    const middle = size / 2;

    const perimeter = 2 * Math.PI * radius;

    const wrapperStyle = useMemo(
      (): StyleProp<ViewStyle> => ({
        width: props.size,
        height: props.size,
        position: "relative",
      }),
      [props.size]
    );

    const innerSize = useMemo(
      () => PixelRatio.roundToNearestPixel(15 * (props.size / 40)),
      [props.size]
    );

    const d = Math.floor(props.size - sw / 4 - 1);
    const innerD = d - (props.shouldInsetFill ? sw * 4 : 0);

    return (
      <View style={wrapperStyle}>
        {props.icon ? (
          <InnerCircle
            size={innerSize}
            icon={props.icon}
            iconColor={props.iconColor ?? "#FFF"}
          />
        ) : null}
        {props.enableNonGradientOptimization &&
        (props.progress === 0 || props.progress === 1 || props.isFilled) ? (
          <StatusCircleNonGradient
            color={
              props.isFilled
                ? props.filledColor
                : props.progress === 0
                ? props.trackBackgroundColor
                : props.filledColor
            }
            diameter={d}
            fillColor={props.isFilled ? props.filledColor : undefined}
            innerDiameter={Math.floor(innerD)}
            filled={false}
            strokeWidth={sw}
          />
        ) : (
          <Svg
            renderToHardwareTextureAndroid
            width={props.size}
            height={props.size}
            style={{
              transform: [{ rotateZ: "-90deg" }, { rotateX: "180deg" }],
            }}
            viewBox={`0 0 ${size} ${size}`}
          >
            <Defs>
              <LinearGradient
                id={"grad" + id}
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <Stop offset="0" stopColor={theme.negative} stopOpacity={1} />
                <Stop offset="0.3" stopColor={theme.negative} stopOpacity={1} />
                <Stop offset="0.9" stopColor={theme.primary} stopOpacity={1} />
              </LinearGradient>
              <Mask
                id={"overlayCircle" + id}
                x="0"
                y="0"
                width={size}
                height={size}
              >
                <Circle
                  cy={middle}
                  cx={middle}
                  r={radius}
                  stroke="white"
                  strokeDasharray={perimeter}
                  strokeDashoffset={props.progress * perimeter}
                  strokeWidth={strokeWidth}
                />
              </Mask>
              <Mask
                id={"middleCircle" + id}
                x="0"
                y="0"
                width={size}
                height={size}
              >
                <Circle
                  cy={middle}
                  cx={middle}
                  r={
                    radius -
                    (props.shouldInsetFill ? strokeWidth / 2 + strokeWidth : 0)
                  }
                  fill="white"
                />
              </Mask>
              <Mask
                id={"coloredCircle" + id}
                x="0"
                y="0"
                width={size}
                height={size}
              >
                <Circle
                  cx={middle}
                  cy={middle}
                  stroke="white"
                  r={radius}
                  strokeWidth={strokeWidth}
                />
                {props.isFilled || props.trackIsFilled ? null : (
                  <Circle
                    cy={middle}
                    cx={middle}
                    r={radius}
                    stroke="black"
                    strokeDasharray={perimeter}
                    strokeDashoffset={props.progress * perimeter}
                    strokeWidth={strokeWidth}
                  />
                )}
              </Mask>
            </Defs>

            <G mask={"url(#coloredCircle" + id + ")"}>
              <Rect
                x="0"
                y="0"
                width={size}
                height={size}
                fill={
                  props.isFilled
                    ? props.filledColor
                    : props.trackIsFilled
                    ? props.trackFillColor
                    : theme.primary
                }
              />
              {!props.isFilled && !props.trackIsFilled ? (
                <Rect
                  x={size - size * 0.8}
                  y={size * 0.5}
                  width={size * 0.8}
                  height={size * 0.5}
                  fill={"url(#grad" + id + ")"}
                />
              ) : null}
            </G>
            {props.isFilled ? (
              <G mask={"url(#middleCircle" + id + ")"}>
                <Rect
                  x="0"
                  y="0"
                  width={size}
                  height={size}
                  fill={props.filledColor}
                />
              </G>
            ) : (
              <G mask={"url(#overlayCircle" + id + ")"}>
                <Rect
                  x="0"
                  y="0"
                  width={size}
                  height={size}
                  fill={props.trackBackgroundColor}
                />
              </G>
            )}
          </Svg>
        )}
      </View>
    );
  }
);

const InnerCircleC = (props: {
  icon: ComponentProps<typeof Feather>["name"];
  iconColor: string;
  size: number;
}) => {
  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        {
          flex: 1,
          justifyContent: "center",
          zIndex: 99,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Feather name={props.icon} color={props.iconColor} size={props.size} />
      </View>
    </View>
  );
};

export const InnerCircle = React.memo(InnerCircleC);

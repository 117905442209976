/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FileConnectionSortField {
  ACCESSED_COUNT = "ACCESSED_COUNT",
  CREATED_AT = "CREATED_AT",
  LAST_ACCESSED_AT = "LAST_ACCESSED_AT",
  NAME = "NAME",
}

export enum FileErrorReason {
  CONVERSION_ERROR = "CONVERSION_ERROR",
  TIMEOUT = "TIMEOUT",
  TOO_LARGE = "TOO_LARGE",
  TYPE_NOT_SUPPORTED = "TYPE_NOT_SUPPORTED",
  UNKNOWN = "UNKNOWN",
}

export enum FileStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  READY = "READY",
  WAIT_FOR_PROCESSING = "WAIT_FOR_PROCESSING",
}

export enum FileType {
  DOC = "DOC",
  DOCX = "DOCX",
  PDF = "PDF",
  XLS = "XLS",
  XLSX = "XLSX",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import React, { useState } from "react";
import { Box } from "arkh";
import { FileUploadArea } from "./FileUploadArea";
import { FilesList } from "./FilesList";
import { SideBar } from "./FileDetailSideBar";

export function Files() {
  const [openFileId, setOpenFileId] = useState<null | string>(null);
  const [sq, setSQ] = useState<null | string>(null);
  return (
    <Box
      style={{
        backgroundColor: "rgb(247, 247, 247)",
        display: "flex",
        position: "relative",
        flex: 1,
      }}
    >
      <FileUploadArea />
      <FilesList
        openFileId={openFileId}
        clearFile={() => {
          setOpenFileId(null);
          setSQ(null);
        }}
        openFile={(fileId, q) => {
          setOpenFileId(fileId);
          setSQ(q);
        }}
      />
      <SideBar
        onDismiss={() => setOpenFileId(null)}
        searchQuery={sq}
        fileId={openFileId}
      />
    </Box>
  );
}

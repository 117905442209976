import { useState, useEffect } from "react";
import { DateTime } from "luxon";

const getTime = () => {
  return DateTime.local();
};

export const useTime = (refreshCycle = 6000, skip = false) => {
  const [now, setNow] = useState(getTime());
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!skip) {
      intervalId = setInterval(() => setNow(getTime()), refreshCycle);
    }
    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow, skip]);
  return now;
};

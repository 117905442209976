import { Feather } from "@expo/vector-icons";
import React from "react";
import { StyleSheet } from "react-native";

import { AHShadowStyle } from "../AHShadowStyle";
import { TouchableBox } from "../AHTheme/Box";

const styles = StyleSheet.create({
  circleButton: {
    width: 50,
    height: 50,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
});

type AHNumberInputButtonProps = {
  disabled: boolean;
  onPress: () => void;
  type: "decrement" | "increment";
};

export function AHNumberInputButton({
  disabled,
  onPress,
  type,
}: AHNumberInputButtonProps) {
  return (
    <TouchableBox
      cornerRadius="medium"
      hitSlop={
        disabled
          ? undefined
          : type === "decrement"
          ? { left: 100, right: 35, top: 10, bottom: 20 }
          : { left: 35, right: 100, top: 10, bottom: 20 }
      }
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.circleButton,
        AHShadowStyle.WEAK,
        { opacity: disabled ? 0 : 1 },
      ]}
    >
      <Feather
        name={type === "decrement" ? "minus" : "plus"}
        size={20}
        color="black"
      />
    </TouchableBox>
  );
}

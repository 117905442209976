import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AHText, Box } from "arkh";
import { Feather, Ionicons } from "@expo/vector-icons";
import "./login.css";

const LogInLink = (props: { title: string; desc: string }) => {
  return (
    <Box
      borderColor="surfaceBorder"
      cornerRadius="large"
      space="large"
      style={{
        borderWidth: 2,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box spaceHorizontal="large" style={{ flex: 1 }}>
        <h2>{props.title}</h2>
        <p>{props.desc}</p>
      </Box>

      <Box
        space="small"
        color="primary"
        style={{
          borderRadius: 50,
          height: 45,
          width: 45,
          alignItems: "center",
        }}
      >
        <AHText color="background">
          <Feather size={22} name="arrow-right" />
        </AHText>
      </Box>
    </Box>
  );
};

const Line = () => {
  return <Box color="surfaceBorder" style={{ flex: 1, height: 2 }} />;
};

const LeftLogin = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <Box style={{ flex: 1, maxWidth: 450 }} space="large">
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src="https://assets.storesprint.app/icons/icon.png"
            alt="StoreSprint Icon"
            style={{ width: 100, margin: "0 auto" }}
          />

          <Box
            spaceVertical="xxlarge"
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Line />
          </Box>
          <button
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              margin: 0,
              padding: 0,
            }}
            onClick={() => loginWithRedirect()}
          >
            <LogInLink title="Logga in" desc="Administrera filer" />
          </button>
          <Box spaceTop="xxlarge">
            <p style={{ textAlign: "center" }}>
              Har er butik inte StoreSprint än?
            </p>
            <a href="https://storesprint.com/contact/">
              <p
                style={{ textAlign: "center", fontWeight: 600, color: "black" }}
              >
                Boka en demo
              </p>
            </a>
          </Box>
          <Box spaceTop="xxlarge">
            <a href="https://storesprint.com">
              <p style={{ textAlign: "center" }}>storesprint.com</p>
            </a>
            <a href="mailto:support@storesprint.app">
              <p style={{ textAlign: "center" }}>support@storesprint.app</p>
            </a>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

const RightSplash = () => {
  return (
    <div
      id="right-splash"
      style={{
        backgroundColor: "#E5F0FF",
        backgroundImage: `url(${require("./images/ipad-splash.png")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        flex: 1,
      }}
    ></div>
  );
};

export function LoginScreen() {
  return (
    <div style={{ flexDirection: "row", minHeight: "100vh", display: "flex" }}>
      <LeftLogin />
      <RightSplash />
    </div>
  );
}

import React from "react";
import { captureException } from "../runtime/captureException";

import { AHText } from "./Text/AHText";

export class AHErrorBoundary extends React.Component<
  object,
  { hasError?: boolean }
> {
  state = { hasError: false };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (__DEV__) {
      console.log("Error: ", error, info);
    }

    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <AHText
          type="body"
          style={{ textAlign: "center", padding: 20, alignSelf: "center" }}
        >
          Something went wrong.
        </AHText>
      );
    }
    return this.props.children;
  }
}

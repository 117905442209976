import { AHText, AHTextFonts, AHTextProps } from "../Text/AHText";
import { Box, TouchableBox } from "../AHTheme/Box";
import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  ViewStyle,
} from "react-native";

import { AHNumberInputButton } from "./AHNumberInputButton";
import { BoxProps } from "../AHTheme/BoxProps";
import { formatToDisplay } from "./formatToDisplay";
import { useTheme } from "../AHTheme/AHTheme";

const filterNumericString = (str: string) => {
  let filteredString = "";
  const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
  let hasDecimal = false;
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const negativeZero = i === 1 && char === "0" && str[i - 1] === "-";
    const doubleZero =
      i === 0 && char === "0" && str.length > 1 && str[i + 1] !== ".";
    if (allowedChars.includes(char) && !doubleZero && !negativeZero) {
      if (!(str[i] === "." && hasDecimal)) {
        filteredString = filteredString + str[i];
      }

      if (str[i] === ".") {
        hasDecimal = true;
      }
    }
  }
  return filteredString;
};

const styles = StyleSheet.create({
  textInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 50,
    justifyContent: "center",
  },
  textInput: {
    fontFamily: "system-regular",
    fontSize: 29,
    width: 135,
    textAlign: "center",
  },
});

type AHNumberInputProps = {
  disabled: boolean;
  suffix?: string;
  setValue: (newValue: number) => void;
  value: number | undefined;
  inputAccessoryViewID?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  clearTextOnFocus: boolean;
  minValue: number;
  maxValue: number;
  buttonsShow?: boolean;
  inputContainerStyle?: StyleProp<ViewStyle>;
};

function limitDecimals(text: string) {
  const a = text.split(".");
  return a[0] + (a[1] !== undefined ? "." + a[1].slice(0, 4) : "");
}

function parseFloatOrZero(p: string) {
  const parsed = parseFloat(p);
  if (Number.isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

export const AHNumberInput = React.forwardRef<
  { focus: () => void },
  AHNumberInputProps & BoxProps & { textInputStyle?: AHTextProps["style"] }
>(
  (
    {
      value,
      setValue,
      disabled,
      suffix,
      clearTextOnFocus,
      minValue,
      maxValue,
      buttonsShow = true,
      onFocus,
      onBlur,
      autoFocus,
      inputContainerStyle,
      inputAccessoryViewID,
      textInputStyle,
      ...boxProps
    },
    ref
  ) => {
    const theme = useTheme();
    const inputRef = useRef<TextInput>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [pendingComma, setPendingComma] = useState(false);
    const [textInputValue, setTextInputValue] = useState(
      value === undefined ? "" : value + ""
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
          setIsFocused(true);
        }
      },
    }));

    const _onFocus = useCallback(() => {
      setIsFocused(true);
      if (onFocus) {
        onFocus();
      }
    }, [onFocus]);

    const _onBlur = useCallback(() => {
      setIsFocused(false);

      onBlur?.();
    }, [onBlur]);

    const onDecrement = useCallback(() => {
      const numericValue = Math.max(
        parseFloatOrZero(textInputValue) - 1,
        minValue
      );
      setTextInputValue(numericValue + "");
      setValue(numericValue);
    }, [minValue, setValue, textInputValue]);

    const onIncrement = useCallback(() => {
      const numericValue = Math.min(
        parseFloatOrZero(textInputValue) + 1,
        maxValue
      );
      setTextInputValue(numericValue + "");
      setValue(numericValue);
    }, [maxValue, setValue, textInputValue]);

    const onChangeText = useCallback(
      (newTextValue) => {
        if (newTextValue === "") {
          setTextInputValue("");
          setValue(0);
          return;
        }

        const val = filterNumericString(newTextValue.replace(",", "."));
        const textValue = limitDecimals(val);
        const numericValue = parseFloat(textValue);
        if (
          !Number.isNaN(numericValue) &&
          numericValue <= maxValue &&
          numericValue >= minValue
        ) {
          setPendingComma(textValue.slice(-1) === ".");
          setTextInputValue(textValue);
          setValue(numericValue);
        }
      },
      [maxValue, minValue, setValue]
    );

    return (
      <Box
        spaceVertical="none"
        spaceHorizontal="none"
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        {...boxProps}
      >
        {buttonsShow ? (
          <AHNumberInputButton
            disabled={disabled}
            onPress={onDecrement}
            type="decrement"
          />
        ) : null}
        <TextInput
          style={{
            position: "absolute",
            bottom: 0,
            opacity: 0,
            width: 1,
            height: 1, // this is a hack to get it to work on android
          }}
          ref={inputRef}
          inputAccessoryViewID={inputAccessoryViewID}
          editable={!disabled}
          focusable
          selectTextOnFocus={Platform.OS === "android"} // when calling focus() on android, the selection of the text is not working so we have to select all of it
          value={textInputValue}
          keyboardType="numeric"
          onChangeText={onChangeText}
          showSoftInputOnFocus
          autoFocus={autoFocus}
          clearTextOnFocus={clearTextOnFocus}
          onFocus={_onFocus}
          onBlur={_onBlur}
        />
        <TouchableBox
          cornerRadius="medium"
          color="background"
          hitSlop={{ top: 40, bottom: 40 }}
          onPress={() => {
            inputRef.current?.focus();
          }}
          style={[
            styles.textInputContainer,
            inputContainerStyle,
            {
              borderWidth: 2,
              borderColor: isFocused ? theme.primary : "transparent",
            },
          ]}
        >
          <AHText
            style={[
              styles.textInput,
              {
                fontFamily: AHTextFonts.MONO,
              },
              textInputStyle,
            ]}
            spaceHorizontal="medium"
            numberOfLines={1}
            adjustsFontSizeToFit
            minimumFontScale={0.3}
          >
            {value === undefined
              ? ""
              : formatToDisplay(value) + (pendingComma ? "." : "")}
            {suffix ? ` ${suffix}` : ""}
          </AHText>
        </TouchableBox>
        {buttonsShow ? (
          <AHNumberInputButton
            disabled={disabled}
            onPress={onIncrement}
            type="increment"
          />
        ) : null}
      </Box>
    );
  }
);

import { AHThemeType, useTheme } from "./AHTheme/AHTheme";
import {
  ActivityIndicator,
  Insets,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { PropsWithChildren, useMemo } from "react";

import { AHText } from "./Text/AHText";
import { Feather } from "@expo/vector-icons";
import { Native } from "sentry-expo";
import { TouchableBox } from "./AHTheme/Box";
import { useLayoutContext } from "./LayoutProvider";

type AHButtonTypes = "default" | "small";
type AHButtonThemes = "default" | "primary" | "negative" | "plain";

export enum AHButtonIconTypes {
  TRASH = "trash-2",
  MOVE_LEFT = "corner-down-left",
  MOVE_RIGHT = "corner-down-right",
  PLUS_CIRCLE = "plus_circle",
  PLUS = "plus",
  NOTIFICATION = "bell",
  NO_NOTIFICATION = "bell-off",
  SEND = "send",
  NEXT = "arrow-right",
}

const getButtonTheme = (
  themeType: AHButtonThemes | undefined,
  theme: AHThemeType
) => {
  switch (themeType) {
    case "plain":
      return {
        container: {
          backgroundColor: "#FFF",
        },
        label: {
          color: "#000",
        },
      };
    case "primary":
      return {
        container: {
          backgroundColor: theme.primary,
        },
        label: {
          color: "white",
        },
      };
    case "negative":
      return {
        container: {
          backgroundColor: theme.negative,
        },
        label: {
          color: "white",
        },
      };
    default:
      return {
        container: {
          backgroundColor: theme.background,
        },
        label: {
          color: theme.darkerText,
        },
      };
  }
};

function useButtonTypeStyle() {
  const layout = useLayoutContext();
  return useMemo(
    () => ({
      small: {
        borderRadius: layout.height.button / 2,
        width: layout.height.button,
        height: layout.height.button,
      },
      default: {
        borderRadius: layout.height.button / 2,
        paddingHorizontal: layout.space.large,
        height: layout.height.button,
      },
    }),
    [layout.height.button, layout.radius.medium, layout.space.large]
  );
}

export const AHButtonLabel = (props: {
  children: React.ReactNode;
  style?: any;
}) => (
  <AHText type="subtitle" style={props.style}>
    {props.children}
  </AHText>
);

export type AHButtonProps = PropsWithChildren<{
  onPress: () => void;
  disabled?: boolean;
  themeType?: AHButtonThemes;
  type?: AHButtonTypes;
  iconType?: AHButtonIconTypes;
  loadingTitle?: string;
  title?: string;
  style?: any;
  loading?: boolean;
  hideIfDisabled?: boolean;
  iconLeft?: boolean;
  iconRight?: boolean;
  labelStyle?: any;
  hitSlop?: Insets;
  status?: {
    type: any;
    message?: string;
  };
}>;

export const AHButton = React.forwardRef<TouchableOpacity, AHButtonProps>(
  ({ type = "default", ...props }: AHButtonProps, ref) => {
    const theme = useTheme();
    const layout = useLayoutContext();
    const buttonTheme = getButtonTheme(props.themeType, theme);
    const buttonTypeStyle = useButtonTypeStyle();
    const typeStyle = buttonTypeStyle[type];
    const title = props.loading ? props.loadingTitle : props.title;
    const renderIcon = (
      buttonTheme: ReturnType<typeof getButtonTheme>,
      style: any
    ) => {
      if (!props.iconType || props.loading) {
        return null;
      }
      if (props.iconType === AHButtonIconTypes.PLUS_CIRCLE) {
        return (
          <View
            style={[
              {
                height: 20,
                width: 20,
                borderRadius: 10,
                backgroundColor: buttonTheme.label.color,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: StyleSheet.hairlineWidth,
                paddingTop: StyleSheet.hairlineWidth,
              },
              style,
            ]}
          >
            <Feather
              name="plus"
              size={14}
              color={buttonTheme.container.backgroundColor}
            />
          </View>
        );
      }

      return (
        <Feather
          name={props.iconType}
          size={20}
          color={buttonTheme.label.color}
          style={style}
        />
      );
    };
    return (
      <TouchableBox
        ref={ref}
        disabled={props.disabled ?? props.loading}
        spaceHorizontal="large"
        {...props}
        onPress={() => {
          props.onPress();
          Native?.addBreadcrumb({
            type: "ui",
            category: "ui.press",
            data: { title: props.title },
          });
        }}
        style={[
          {
            opacity: props.disabled ? (props.hideIfDisabled ? 0.0 : 0.6) : 1,

            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          },
          buttonTheme.container,
          typeStyle,
          props.style,
        ]}
      >
        {props.iconLeft
          ? renderIcon(buttonTheme, { marginRight: title ? 8 : 0 })
          : null}
        {title ? (
          <AHButtonLabel style={[buttonTheme.label, props.labelStyle]}>
            {title}
          </AHButtonLabel>
        ) : (
          props.children
        )}
        {props.iconRight
          ? renderIcon(buttonTheme, { marginLeft: title ? 8 : 0 })
          : null}
        {props.loading ? (
          <ActivityIndicator
            color={buttonTheme.label.color}
            animating={props.loading}
            hidesWhenStopped
            style={{ marginLeft: title ? 8 : 0 }}
          />
        ) : null}
      </TouchableBox>
    );
  }
);

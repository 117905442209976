import React from "react";
import { StyleProp, ViewStyle, StyleSheet } from "react-native";

import { useTheme } from "../AHTheme/AHTheme";
import { Box } from "../AHTheme/Box";
import { BoxProps } from "../AHTheme/BoxProps";

export const AHSeparator = ({
  style,
  ...boxProps
}: { style?: StyleProp<ViewStyle> } & BoxProps) => {
  const color = useTheme().surfaceBorder;
  return (
    <Box {...boxProps}>
      <Box
        borderColor="surfaceBorder"
        style={[
          {
            alignSelf: "stretch",
            backgroundColor: color,
            borderBottomWidth: StyleSheet.hairlineWidth,
          },
          style,
        ]}
      />
    </Box>
  );
};

import React from "react";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { config } from "../config";
import { useEffect, useMemo, useState } from "react";
import { createApolloClient } from "graphql-client";
import { ApolloProvider } from "@apollo/client";
import { Files } from "./Files";
import { LoginScreen } from "./LoginScreen";
import { FontLoader } from "./FontLoader";
import { Navbar } from "./NavBar";
import { PortalProvider } from "@gorhom/portal";
import { AHQueryHandler, AHQueryHandlerError } from "arkh";

function AuthHandler() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  if (error) {
    console.log(error);
  }
  return (
    <AHQueryHandler
      error={error}
      networkStatus={isLoading}
      renderError={(err, loading) => (
        <AHQueryHandlerError
          isLoading={loading}
          onPress={() => {
            window.location.reload();
          }}
        />
      )}
      renderLoaded={() => {
        if (isAuthenticated) {
          return <LoggedIn />;
        }
        return <LoginScreen />;
      }}
    />
  );
}

export function App() {
  return (
    <FontLoader>
      <Auth0Provider
        clientId={config.auth.oauth.clientId}
        domain={config.auth.domain}
        issuer={config.auth.oauth.issuer}
        redirectUri={window.location.href}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <AuthHandler></AuthHandler>
      </Auth0Provider>
    </FontLoader>
  );
}

function LoggedIn() {
  const {
    user,

    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const userSub = user?.sub;
  const [storeId, setStoreId] = useState<string | undefined>(
    (userSub ? localStorage.getItem(`storeId:${userSub}`) : undefined) ??
      undefined
  );

  useEffect(() => {
    if (storeId && userSub) {
      localStorage.setItem(`storeId:${userSub}`, storeId);
    }
  }, [storeId, userSub]);

  const apollo = useMemo(() => {
    return createApolloClient({
      baseUrl: config.baseUrl,
      baseWSUrl: config.baseWSUrl,
      onError: () => {
        loginWithRedirect();
      },
      onRefreshToken: async () => {
        await loginWithRedirect();
        throw new Error();
      },
      addBreadcrumb: (breadcrumb) => {},
      timeout: 20000,
      getAuthSession: () => {
        console.log("get auth session");
        return getAccessTokenSilently().then((k) => {
          return { authToken: k, storeId };
        });
      },
    });
  }, [storeId, createApolloClient, getAccessTokenSilently]);

  return (
    <ApolloProvider client={apollo.client}>
      <PortalProvider>
        <div
          key={storeId}
          onDrop={(e) => {
            e.preventDefault();
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <Navbar storeId={storeId} setStoreId={setStoreId} />
          <Files />
        </div>
      </PortalProvider>
    </ApolloProvider>
  );
}

import React from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";
import { Box } from "../AHTheme/Box";

export const AHWidgetBody: React.FC<TouchableOpacityProps> = ({
  style,
  children,
  ...props
}) => {
  return (
    <TouchableOpacity {...props} style={[style]}>
      <Box>{children}</Box>
    </TouchableOpacity>
  );
};
